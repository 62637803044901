import { useRecoilValue } from 'recoil'
import { NodeId } from '../../../../../../../../models/treeModels/treeTypes'
import { userState } from '../../../../../../../../states/UserState'
import { useState } from 'react'
import copyPasteClipboardImg from '../../../../../../../../resources/images/225-copy_to_clipboard.svg'
import { getText } from '../../../../../../../../services/textFunctions'

type Props = {
  nodeId: NodeId
  treeIndex: number
  handleCopyNodeClick: (e: any) => void
  copied: boolean
}

const CopyNodeButton = (props: Props) => {
  const user = useRecoilValue(userState)

  const [hoverOn, setHoverOn] = useState(false)

  return (
    <>
      <div
        className="copyNodeButtonContainer"
        id={`copyNodeButtonContainer-${props.treeIndex}_${props.nodeId}`}
        style={
          user.settings.language === 'norwegian'
            ? { width: '230px', right: '-215px' }
            : {}
        }
        onClick={props.handleCopyNodeClick}
        onMouseEnter={() => setHoverOn(true)}
        onMouseLeave={() => setHoverOn(false)}
      >
        <div
          className={`copyNodeButtonImgContainer ${hoverOn ? 'hover' : ''}`}
          id={`copyNodeButtonImgContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <img
            src={copyPasteClipboardImg}
            alt="copyPasteClipboardImg"
            className="copyNodeButtonImg"
            id={`copyNodeButtonImg-${props.treeIndex}_${props.nodeId}`}
          />
        </div>
        <div
          className="copyNodeButtonTextContainer"
          id={`copyNodeButtonTextContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <p
            className={`copyNodeButtonText ${hoverOn ? 'hover' : ''}`}
            id={`copyNodeButtonText-${props.treeIndex}_${props.nodeId}`}
            data-textattribute={props.copied ? 'button-155' : 'button-139'}
          >
            {props.copied
              ? getText('button-155', user.settings)
              : getText('button-139', user.settings)}
          </p>
        </div>
      </div>
    </>
  )
}

export default CopyNodeButton
