import React, { useEffect, useRef, useState } from 'react'
import checklistImg from '../../resources/images/222-checklist-icon.svg'
import checkTickImg from '../../resources/images/223-checklist-tick.svg'
import checkTickUndoneImg from '../../resources/images/224-checklist-tick-undone.svg'
import { useRecoilValue } from 'recoil'
import { userState } from '../../states/UserState'
import FullScreenPopMessage from '../popUps/FullScreenPopMessage'
import celebration from '../../resources/animations/celebration.json'
import celebrationBig from '../../resources/animations/celebration_big.json'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { getText } from '../../services/textFunctions'
import { freemiumState } from '../../states/FreemiumState'
import { logActivity } from '../../services/commonFunctions'
import englishTexts from '../../resources/texts/englishTexts.json'
import { UserSettings } from '../../models/userSettings'
import { AvailableLanguages } from '../../models/enums'

type Props = {
  showHide: boolean
  firstTimeLogin?: boolean
  setFirstTimeLogin: (value: boolean) => void
}

export default function ChecklistComponent(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [menuOn, setMenuOn] = useState(false)
  const [showVideo, setShowVideo] = useState<string | undefined>(
    props.firstTimeLogin && !user.checklist.introVideo
      ? 'Watch intro video'
      : undefined,
  )
  const [checklistProgress, setChecklistProgress] = useState(
    user.calculateChecklistDone(freemium.isFreemium),
  )
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)
  const [showCelebration, setShowCelebration] = useState(false)
  const [showDoneChecklist, setShowDoneChecklist] = useState(false)

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (
      user.calculateChecklistDone(freemium.isFreemium) !== checklistProgress
    ) {
      setShowCelebration(true)
      interval = setInterval(() => {
        setChecklistProgress((prevNumber) => {
          if (prevNumber < user.calculateChecklistDone(freemium.isFreemium)) {
            return prevNumber + 1
          } else {
            lottieRef.current!.play()
            setShowDoneChecklist(
              user.calculateChecklistDone(freemium.isFreemium) === 100,
            )
            clearInterval(interval)
            return prevNumber
          }
        })
      }, 80)
    }

    return () => clearInterval(interval)

    // eslint-disable-next-line
  }, [user.calculateChecklistDone(freemium.isFreemium)])

  const getChecklistItem = (
    checklist: boolean,
    textAttribute: keyof typeof englishTexts,
  ) => (
    <div className="checklistMenuRow">
      <div className="checklistMenuRowImgContainer">
        <img
          src={checklist ? checkTickImg : checkTickUndoneImg}
          alt={checklist ? 'checkTickImg' : 'checkTickUndoneImg'}
          className="checklistMenuRowImg"
        />
      </div>
      <div
        className={`checklistMenuRowText ${checklist ? 'done' : ''}`}
        onClick={() => {
          setMenuOn(false)
          //Mixpanel 160 (All)
          logActivity(
            freemium.isFreemium,
            `Pressed '${getText(textAttribute, {
              ...user.settings,
              language: AvailableLanguages.english,
            } as UserSettings)}' in checklist`,
          )
          setShowVideo(
            getText(textAttribute, {
              ...user.settings,
              language: AvailableLanguages.english,
            } as UserSettings),
          )
        }}
        id="checklistMenu-introVideo"
      >
        {getText(textAttribute, user.settings)}
      </div>
    </div>
  )

  return (
    <>
      {menuOn ? (
        <div
          className="transparent-Background"
          onClick={() => setMenuOn(false)}
        ></div>
      ) : null}
      <div
        id="checklistContainer"
        className={
          props.showHide ? 'checklistContainer open' : 'checklistContainer'
        }
      >
        {menuOn ? (
          <>
            <div className="checklistMenuContainer on" id="checklistMenuOpen">
              <div className="checklistMenuRow title">
                <div className="checklistMenuRowImgContainer">
                  <img
                    src={checklistImg}
                    alt="checklistImg"
                    className="checklistMenuRowImg"
                  />
                </div>
                <div
                  className="checklistMenuTitleRowText"
                  onClick={() => {
                    setMenuOn(false)
                  }}
                  data-textattribute="title-215"
                  id="checklistMenu-title"
                >
                  {`${getText(
                    'title-215',
                    user.settings,
                  )} ${user.calculateChecklistDone(freemium.isFreemium)}%`}
                </div>
              </div>
              {/* data-textattribute= 'title-210' */}
              {getChecklistItem(user.checklist.introVideo, 'title-210')}
              {/* data-textattribute= 'title-211' */}
              {getChecklistItem(user.checklist.accessTutorial, 'title-211')}
              {/* data-textattribute= 'title-212' */}
              {getChecklistItem(user.checklist.createCase, 'title-212')}
              {/* data-textattribute= 'title-213' */}
              {getChecklistItem(user.checklist.analyzeScenario, 'title-213')}
              {/* data-textattribute= 'title-214' */}
              {!freemium.isFreemium &&
                getChecklistItem(user.checklist.downloadReport, 'title-214')}
            </div>
          </>
        ) : (
          <div className="checklistMenuContainer" id="checklistMenuClosed">
            <div className="checklistMenuRow">
              <div className="checklistMenuRowImgContainer">
                <img
                  src={checklistImg}
                  alt="checklistImg"
                  className="checklistMenuRowImg"
                />
              </div>
              <p
                className="checklistText"
                onClick={() => {
                  setMenuOn(true)
                  //Mixpanel 21 (All)
                  logActivity(freemium.isFreemium, 'Opened the checklist menu')
                }}
                data-textattribute="title-215"
                id="helpMenu-checklist"
              >
                {`${getText('title-215', user.settings)} ${checklistProgress}%`}
              </p>
            </div>
          </div>
        )}
      </div>
      {showVideo && (
        <FullScreenPopMessage
          id={'popUpChecklistVideo'}
          backGroundColor="#6f6f6f" //tuesdayGray
          zIndex={2000}
          titleTextAttribute={undefined}
          blur
          cancelFunction={() => {
            setShowVideo(undefined)
            props.setFirstTimeLogin(false)
          }}
          infoVideo={showVideo}
        />
      )}
      {showDoneChecklist && (
        <FullScreenPopMessage
          id={'popUpChecklistVideo'}
          backGroundColor="#6f6f6f" //tuesdayGray
          zIndex={2000}
          titleTextAttribute={'title-216'}
          warningTitle={getText('title-216', user.settings)}
          warningTextContainer={
            <p
              className="warningText"
              id="popUpWarningText-completedChecklist"
              data-textattribute="description-156"
            >
              {getText('description-156', user.settings)}
            </p>
          }
          blur
          forCelebration
        />
      )}
      {showCelebration && (
        <Lottie
          id="celebrationAnimation"
          lottieRef={lottieRef}
          animationData={
            user.calculateChecklistDone(freemium.isFreemium) === 100
              ? celebrationBig
              : celebration
          }
          style={{
            height:
              user.calculateChecklistDone(freemium.isFreemium) === 100
                ? '100vh'
                : 300,
            left: '10px',
            bottom: '-10px',
            zIndex: '2100',
            position: 'fixed',
          }}
          autoplay={false}
          loop={false}
          onComplete={() => {
            setShowCelebration(false)
            setShowDoneChecklist(false)
          }}
        />
      )}
    </>
  )
}
