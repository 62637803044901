import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import {
  deepCloneObject,
  isDevEnv,
  isStagingEnv,
  isTestEnv,
} from '../../../../../../../../services/commonFunctions'
import { debuggingState } from '../../../../../../../../states/DebuggingState'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import GroupGraphComponent from './GroupGraphComponent'
import SpotGraphComponent from './SpotGraphComponent'
import { includedIncurredCosts } from '../../../../../../../../services/resultsFunctions'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'

type Props = {
  resultsFromBackend: ResultsObject
  resultsSnapshot: ScenarioSnapshot
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
  expectedValue: number
}

export default function DistributionGraph(props: Props) {
  // *** Declare the height of the table depending on the height of the div at the time of generation of the table
  const debugging = useRecoilValue(debuggingState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const widthOfDiv = 600

  const heightOfDiv = (widthOfDiv * 4) / 10
  const [segmentedData, setSegmentedData] = useState(
    deepCloneObject(props.resultsFromBackend.bellgraph.graph),
  )

  const [analyticalDistribution, setAnalyticalDistribution] = useState<
    number[][] | undefined
  >(undefined)
  const [analyticalDistributionDetailed, setAnalyticalDistributionDetailed] =
    useState<number[][][] | undefined>(undefined)

  const maxAmount =
    props.resultsFromBackend.bellgraph.max_amount +
    includedIncurredCosts(
      'client',
      'both',
      resultsSettings.settings.includeIncurredCosts,
      props.resultsSnapshot,
    )
  const minAmount =
    props.resultsFromBackend.bellgraph.min_amount +
    includedIncurredCosts(
      'client',
      'both',
      resultsSettings.settings.includeIncurredCosts,
      props.resultsSnapshot,
    )

  const stepSize = props.resultsFromBackend.bellgraph.step_size
  const maxProbability = props.resultsFromBackend.bellgraph.max_probability

  useEffect(() => {
    //Allow the analytical distribution
    if (props.resultsFromBackend.table.length <= 40) {
      let tempAnalyticalData: [number, number][] = []
      for (let outcome of props.resultsFromBackend.table) {
        let indexOfOutcomeInTempAnalytiacalData = tempAnalyticalData.findIndex(
          (sampleOutcome) => sampleOutcome[0] === outcome.financial_outcome,
        )
        if (indexOfOutcomeInTempAnalytiacalData !== -1) {
          tempAnalyticalData[indexOfOutcomeInTempAnalytiacalData][1] +=
            outcome.probability_of_outcome
        } else {
          tempAnalyticalData.push([
            outcome.financial_outcome,
            outcome.probability_of_outcome,
          ])
        }
      }

      let analyticalSegmentsLimitsArray = [minAmount]
      let analyticalSegments = 60
      const analyticalStep = (maxAmount - minAmount) / analyticalSegments
      const segmentsOfOutcomes: number[][] = [[]]
      const segmentsOfOutcomesDetailed: number[][][] = [[]]

      for (let i = 1; i < analyticalSegments; i++) {
        analyticalSegmentsLimitsArray.push(minAmount + i * analyticalStep)
        segmentsOfOutcomes.push([])
        segmentsOfOutcomesDetailed.push([])
      }
      analyticalSegmentsLimitsArray.push(maxAmount)
      if (
        !analyticalSegmentsLimitsArray.includes(0) &&
        0 > minAmount &&
        0 < maxAmount
      ) {
        analyticalSegmentsLimitsArray.push(0)
        analyticalSegmentsLimitsArray.sort((a, b) => a - b)
        segmentsOfOutcomes.push([])
        segmentsOfOutcomesDetailed.push([])
        analyticalSegments++
      }

      for (let outcome of tempAnalyticalData) {
        let outcomeToCheck =
          outcome[0] +
          includedIncurredCosts(
            'client',
            'both',
            resultsSettings.settings.includeIncurredCosts,
            props.resultsSnapshot,
          )

        for (
          let index = 0;
          index < analyticalSegmentsLimitsArray.length - 1;
          index++
        ) {
          if (index === 0) {
            if (
              outcomeToCheck <= analyticalSegmentsLimitsArray[1] &&
              outcomeToCheck !== 0
            ) {
              segmentsOfOutcomesDetailed[index].push([outcome[0], outcome[1]])
              if (segmentsOfOutcomes[index][0] !== undefined) {
                segmentsOfOutcomes[index][1] += outcome[1]
              } else {
                segmentsOfOutcomes[index][0] = outcome[0]
                segmentsOfOutcomes[index][1] = outcome[1]
              }

              break
            }
          } else {
            if (
              outcomeToCheck > analyticalSegmentsLimitsArray[index] &&
              outcomeToCheck <= analyticalSegmentsLimitsArray[index + 1] &&
              outcomeToCheck !== 0
            ) {
              segmentsOfOutcomesDetailed[index].push([outcome[0], outcome[1]])
              if (segmentsOfOutcomes[index][0] !== undefined) {
                segmentsOfOutcomes[index][1] += outcome[1]
              } else {
                segmentsOfOutcomes[index][0] = outcome[0]
                segmentsOfOutcomes[index][1] = outcome[1]
              }
              break
            }
          }
        }
      }

      for (let outcome of tempAnalyticalData) {
        if (outcome[0] === 0) {
          segmentsOfOutcomes.push(outcome)
          segmentsOfOutcomesDetailed.push([[outcome[0], outcome[1]]])
        }
      }

      for (let outcome of segmentsOfOutcomes) {
        if (outcome[0] !== undefined) {
          outcome[0] += includedIncurredCosts(
            'client',
            'both',
            resultsSettings.settings.includeIncurredCosts,
            props.resultsSnapshot,
          )
        }
      }

      for (let outcomesArray of segmentsOfOutcomesDetailed) {
        for (let outcome of outcomesArray) {
          if (outcome[0] !== undefined) {
            outcome[0] += includedIncurredCosts(
              'client',
              'both',
              resultsSettings.settings.includeIncurredCosts,
              props.resultsSnapshot,
            )
          }
        }
      }

      setAnalyticalDistribution(segmentsOfOutcomes)
      setAnalyticalDistributionDetailed(segmentsOfOutcomesDetailed)
      // let totalProbabilty = 0
      // for (let outcome of tempAnalyticalData) {
      //   totalProbabilty += outcome[1]
      // }
      // console.log('totalProbabilty')
      // console.log(totalProbabilty)
    } else {
      let tempSegmentedData = deepCloneObject(
        props.resultsFromBackend.bellgraph.graph,
      )
      for (let row of tempSegmentedData) {
        row[0] += includedIncurredCosts(
          'client',
          'both',
          resultsSettings.settings.includeIncurredCosts,
          props.resultsSnapshot,
        )
        row[1] += includedIncurredCosts(
          'client',
          'both',
          resultsSettings.settings.includeIncurredCosts,
          props.resultsSnapshot,
        )
      }
      setAnalyticalDistribution(undefined)
      setAnalyticalDistributionDetailed(undefined)
      setSegmentedData(tempSegmentedData)
    }
    // eslint-disable-next-line
  }, [props.resultsFromBackend, resultsSettings])

  return (
    <>
      <div className="chartBell" id="distributionGraph">
        <div className="sub-chart">
          {analyticalDistribution && analyticalDistributionDetailed ? (
            <SpotGraphComponent
              maxProbability={maxProbability}
              minAmount={minAmount}
              maxAmount={maxAmount}
              resultsFromBackend={props.resultsFromBackend}
              currentSnapshot={props.resultsSnapshot}
              widthOfDiv={widthOfDiv}
              heightOfDiv={heightOfDiv}
              stepSize={stepSize}
              graphData={analyticalDistribution}
              tooltipData={analyticalDistributionDetailed}
              pdfPreview={props.pdfPreview}
              reportWhitelabel={props.reportWhitelabel}
              expectedValue={props.expectedValue}
            />
          ) : (
            segmentedData && (
              <GroupGraphComponent
                maxProbability={maxProbability}
                minAmount={minAmount}
                maxAmount={maxAmount}
                resultsFromBackend={props.resultsFromBackend}
                currentSnapshot={props.resultsSnapshot}
                widthOfDiv={widthOfDiv}
                heightOfDiv={heightOfDiv}
                stepSize={stepSize}
                graphData={segmentedData}
                pdfPreview={props.pdfPreview}
                reportWhitelabel={props.reportWhitelabel}
                expectedValue={props.expectedValue}
              />
            )
          )}
        </div>
        {(isDevEnv() || isStagingEnv() || isTestEnv()) && debugging ? (
          <div className="probabilitySum">
            Probability of Outcomes sum ={' '}
            {Math.round(
              props.resultsFromBackend.bellgraph.total_probability * 100,
            )}{' '}
            %
          </div>
        ) : null}
      </div>
    </>
  )
}
