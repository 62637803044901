import React, { useEffect, useState } from 'react'

import SelectionGraph from './graphComponents/selectionGraph/SelectionGraph'
import DistributionGraph from './graphComponents/distributionGraph/DistributionGraph'
import { ResultsObject } from '../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../models/scenarioSnapshot'
import { getText } from '../../../../../../services/textFunctions'
import { ActionMessage, VideoPostName } from '../../../../../../models/enums'
// import VideoInfoButton from '../../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { fullscreenActionMessageState } from '../../../../../../states/FullScreenActionMessageState'
import DownloadImage from '../../../../../../Components/downloadImages/DownloadImage'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import GetPayGraph from './graphComponents/getPayGraph/GetPayGraph'
import AwaLegendComponent from '../../../../../../Components/common/AwaLegendComponent'
import { getComplexValueText } from '../../../../../../services/commonFunctions'
import { reverseCase } from '../../../../../../services/reverseCaseFunctions'
import { resultsSettingsState } from '../../../../../../states/ResultsSettingsState'
import {
  getSubtitleForGetPayGraph,
  includedIncurredCosts,
} from '../../../../../../services/resultsFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  typeOfGraph: 'distributionGraph' | 'selectionGraph' | 'getPayGraph'
  resultsSnapshot: ScenarioSnapshot
  widthOfDiv: number
  heightOfDiv: number
  setVideoPostName: (param: VideoPostName | undefined) => void
  setShowAWADetails: (showAWADetails: boolean) => void
  resultsViewParty: 'client' | 'opposing'
  loadingResultsSettings?: boolean
}

export default function Graph(props: Props) {
  //const { getValues } = useFormContext()
  /* 
  
  tabs:
  0 - DISTRIBUTION GRAPH
  1 - SELECTION GRAPH
  2 - GET PAY GRAPH
  
  */
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [saveGraphImageName, setSaveGraphImageName] = useState('')
  const reverseSnapshot = reverseCase(scenarioSnapshot.currentSnapshot)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const resultsSnapshot =
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot
      : reverseSnapshot

  useEffect(() => {
    let tempSaveGraphImageName = `${scenarioIdentity.caseName} - ${
      scenarioIdentity.scenarioName
    } - ${
      props.typeOfGraph === 'selectionGraph'
        ? getText('title-265', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        : props.typeOfGraph === 'distributionGraph'
        ? getText('title-264', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        : getText('button-160', user.settings)
    } - ${
      props.resultsViewParty === 'client'
        ? getText('title-260', user.settings, partiesFormat)
        : getText('title-261', user.settings, partiesFormat)
    }`
    setSaveGraphImageName(tempSaveGraphImageName)
    // eslint-disable-next-line
  }, [scenarioIdentity, props.typeOfGraph])

  function getGraphTitle(
    typeOfGraph: 'distributionGraph' | 'selectionGraph' | 'getPayGraph',
  ) {
    if (typeOfGraph === 'distributionGraph') {
      //data-textattribute = 'title-264'
      return getText('title-264', user.settings)
    } else if (typeOfGraph === 'selectionGraph') {
      //data-textattribute = 'title-265'
      return getText('title-265', user.settings)
    } else if (typeOfGraph === 'getPayGraph') {
      return getText('title-263', user.settings)
    }
  }
  function getGraphSubTitle(
    typeOfGraph: 'distributionGraph' | 'selectionGraph' | 'getPayGraph',
  ) {
    if (typeOfGraph === 'selectionGraph') {
      //data-textattribute = 'description-255'
      return `${getText(
        'description-255',
        user.settings,
      )} ${getComplexValueText(
        resultsSnapshot,
        user.settings,
        resultsSettings.settings.includeIncurredCosts,
        true,
      )}`
    } else if (typeOfGraph === 'distributionGraph') {
      //data-textattribute = 'description-256'
      return `${getText(
        'description-256',
        user.settings,
      )} ${getComplexValueText(
        resultsSnapshot,
        user.settings,
        resultsSettings.settings.includeIncurredCosts,
        true,
      )}`
    } else if (typeOfGraph === 'getPayGraph') {
      return getSubtitleForGetPayGraph(
        props.resultsFromBackend,
        resultsSnapshot,
        user.settings,
        resultsSettings,
      )
    }
  }

  return (
    <div
      className="tool-component results-tool-component"
      id={`graphComp-${props.typeOfGraph}`}
      style={
        fullscreenActionMessage === ActionMessage.downloadingImage
          ? { marginTop: 0 }
          : undefined
      }
    >
      <div
        className="downloadImageComponentContainer"
        style={
          fullscreenActionMessage === ActionMessage.downloadingImage
            ? { visibility: 'hidden' }
            : undefined
        }
      >
        <DownloadImage
          id={props.typeOfGraph}
          tooltipText={`${
            props.typeOfGraph === 'selectionGraph'
              ? getText('tooltip-32', user.settings)
              : props.typeOfGraph === 'distributionGraph'
              ? getText('tooltip-33', user.settings)
              : getText('tooltip-67', user.settings)
          }`}
          tooltipTextAttribute={
            props.typeOfGraph === 'selectionGraph'
              ? 'tooltip-32'
              : props.typeOfGraph === 'distributionGraph'
              ? 'tooltip-33'
              : 'tooltip-00'
          }
          imageElementId={`graphComp-${props.typeOfGraph}`}
          saveName={saveGraphImageName}
          downloadImageType="results"
        />
      </div>
      <div
        className="tool-component-title graphTitle"
        id={`graphTitle-${props.typeOfGraph}`}
        data-textattribute="title-39"
      >
        {fullscreenActionMessage !== ActionMessage.downloadingImage ? (
          <>
            {getGraphTitle(props.typeOfGraph)}
            {/* <div className="videoInfoButtonComponentContainer">
              <VideoInfoButton
                textAttribute="tooltip-19"
                postName={VideoPostName.resultsGraphs}
                setVideoPostName={props.setVideoPostName}
              />
            </div> */}
          </>
        ) : (
          getGraphTitle(props.typeOfGraph)
        )}
      </div>
      <p className="graphSubtitle" id={`graphSubtitle-${props.typeOfGraph}`}>
        {getGraphSubTitle(props.typeOfGraph)}
      </p>
      <div
        className="graphContainer"
        id={`graphContainerOut-${props.typeOfGraph}`}
      >
        <div
          className="tab-content"
          id={`graphContainer-${props.typeOfGraph}`}
          data-openreplay-obscured
          style={
            props.typeOfGraph === 'selectionGraph'
              ? { maxHeight: props.widthOfDiv / 2.5 }
              : undefined
          }
        >
          {props.typeOfGraph === 'selectionGraph' ? (
            <SelectionGraph
              resultsFromBackend={props.resultsFromBackend}
              resultsSnapshot={resultsSnapshot}
              loadingResultsSettings={props.loadingResultsSettings}
              resultsViewParty={props.resultsViewParty}
              expectedValue={
                props.resultsFromBackend.value_of_claim +
                includedIncurredCosts(
                  props.resultsViewParty === 'client' ? 'client' : 'opposing',
                  'both',
                  resultsSettings.settings.includeIncurredCosts,
                  resultsSnapshot,
                )
              }
            />
          ) : props.typeOfGraph === 'distributionGraph' ? (
            <DistributionGraph
              resultsFromBackend={props.resultsFromBackend}
              resultsSnapshot={resultsSnapshot}
              expectedValue={
                props.resultsFromBackend.value_of_claim +
                includedIncurredCosts(
                  props.resultsViewParty === 'client' ? 'client' : 'opposing',
                  'both',
                  resultsSettings.settings.includeIncurredCosts,
                  resultsSnapshot,
                )
              }
            />
          ) : props.typeOfGraph === 'getPayGraph' ? (
            <GetPayGraph
              resultsFromBackend={props.resultsFromBackend}
              resultsSnapshot={resultsSnapshot}
            />
          ) : null}
        </div>
      </div>
      <AwaLegendComponent
        resultsFromBackend={props.resultsFromBackend}
        user={user}
        currentSnapshot={props.resultsSnapshot}
        type={props.typeOfGraph}
        setShowAWADetails={props.setShowAWADetails}
        downloadingImage={
          fullscreenActionMessage === ActionMessage.downloadingImage
        }
      />
    </div>
  )
}
