import React, { useEffect, useState } from 'react'
import createPdfImg from '../../../../../../resources/images/156-createReport.svg'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import {
  createReportDetail,
  getReportDetail,
  getWhitelabelProperties,
} from '../../../../../../services/requests'
import {
  deepCloneObject,
  findHandlingErrorState,
  logActivity,
  roundNumberTo,
} from '../../../../../../services/commonFunctions'
import { ReportSectionClass } from '../../../../../../models/reportModels/reportSection'
import { pageDepthState } from '../../../../../../states/PageDepthState'
import { reportState } from '../../../../../../states/ReportState'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { handlingErrorsState } from '../../../../../../states/HandlingErrorsState'
import { CircularProgress } from '@mui/material'
import { Button } from '../../../../../../Components/Buttons/Button/Button'
import { reportMenuState } from '../../../../../../states/ReportMenuState'
import { savedReportState } from '../../../../../../states/SavedReportState'
import { onlineState } from '../../../../../../states/OnlineState'
import {
  ReportDataType,
  ReportStateType,
  ReportWhitelabel,
} from '../../../../../../models/reportModels/reportGeneralTypes'
import { AccessRole } from '../../../../../../models/enums'
import PreviewPdfReport from './pdfReportComponents/PreviewPdfReport'
import ReportChangesMessageComponent from './pdfReportComponents/pdfReportMenuComponents/ReportChangesMessageComponent'
import { SectionOrderMenu } from './pdfReportComponents/pdfReportMenuComponents/SectionOrderMenu'
import {
  adjustClaimsInReport,
  snapshotHasEnforcementRisk,
  snapshotHasLegalCosts,
} from '../../../../../../services/reportFunctions'
import { userState } from '../../../../../../states/UserState'
import { getText } from '../../../../../../services/textFunctions'
import { manosVersion } from '../../../../../../ManosVersion'
import { tikasVersion } from '../../../../../../TikasVersion'
import { snapshotChangeKillsResults } from '../../../../../../services/killingTheResultsFunctions'
import { featuresState } from '../../../../../../states/FeaturesState'
import { freemiumState } from '../../../../../../states/FreemiumState'
import { getFreemiumReportDetail } from '../../../../../../freemiumServices/freemiumRequests'
import OptionsComponent from '../smallComponents/OptionsComponent'
import useWindowSize from '../../../../../../customHooks/useWindowSize'

type Props = {
  resultsFromBackend: any
  ownRole: AccessRole
  resultsViewParty: 'client' | 'opposing'
  setResultsViewParty: (value: 'client' | 'opposing') => void
  fixedOptionsLeft: number | undefined
  setFixedOptionsLeft: (value: number | undefined) => void
}

export default function ResultsOptionsContainer(props: Props) {
  const [scenarioIdentity, setScenarioIdentity] = useRecoilState(
    scenarioIdentityState,
  )
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot
  const [report, setReport] = useRecoilState(reportState)
  const [savedReport, setSavedReport] = useRecoilState(savedReportState)
  const [loadingReport, setLoadingReport] = useState(false)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const setPageDepth = useSetRecoilState(pageDepthState)
  const [reportMenu, setReportMenu] = useRecoilState(reportMenuState)
  const online = useRecoilValue(onlineState)
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [reportWhitelabel, setReportWhitelabel] = useState<
    ReportWhitelabel | undefined
  >(undefined)
  const features = useRecoilValue(featuresState)

  const [heightOfOuterContainer, setHeightOfOuterContainer] = useState(
    window.innerHeight - 100,
  )
  const windowSize = useWindowSize()

  useEffect(() => {
    window.addEventListener('resize', calculateHeight)
    return () => {
      window.removeEventListener('resize', calculateHeight)
    }
    // eslint-disable-next-line
  }, [window])

  function calculateHeight() {
    setHeightOfOuterContainer(window.innerHeight - 100)
  }

  function showPdfReport(tempReport?: ReportStateType) {
    setPageDepth(3.1)
    let tempReportMenu = deepCloneObject(reportMenu)
    tempReportMenu.reportWithDifferentResults = false
    if (
      tempReport &&
      snapshotChangeKillsResults(
        tempReport.reportScenarioSnapshot,
        currentSnapshot,
      )
    ) {
      tempReportMenu.reportWithDifferentResults = true
    }

    tempReportMenu.previewPdfOn = true
    tempReportMenu.downloadReportFixedHeaderOn = true
    tempReportMenu.pdfMenuOn = true
    setReportMenu(tempReportMenu)
  }

  async function getWhitelabelReportProperties() {
    if (!features.use_whitelabel) {
      return
    }

    const res = await getWhitelabelProperties()
    if (!('errorCode' in res)) {
      setReportWhitelabel({
        reportTitle: {
          font: res.data.report_title.font,
          color: res.data.report_title.color,
        },
        reportSubtitle: {
          font: res.data.report_subtitle.font,
          color: res.data.report_subtitle.color,
        },
        sectionTitle: {
          font: res.data.section_title.font,
          color: res.data.section_title.color,
        },
        sectionDescription: {
          font: res.data.section_description.font,
          color: res.data.section_description.color,
        },
        disclaimer: {
          font: res.data.disclaimer.font,
          color: res.data.disclaimer.color,
          text: res.data.disclaimer.text,
        },
      })
    }
  }

  async function createPdfReport() {
    if (online.networkOn) {
      setLoadingReport(true)
      let tempReport = deepCloneObject(report)
      let tempScenarioIdentity = deepCloneObject(scenarioIdentity)
      tempReport.reportData = {
        date: 'currentDate',
        version: roundNumberTo(manosVersion + tikasVersion, 2),
        sections: [
          deepCloneObject(ReportSectionClass.ExecutiveSummaryDefault(0)),
          deepCloneObject(ReportSectionClass.ProceedingsDefault('', '', 1)),
          deepCloneObject(ReportSectionClass.ClaimsOverviewDefault(2)),
          deepCloneObject(ReportSectionClass.ClaimsDefault(currentSnapshot, 3)),
          deepCloneObject(
            ReportSectionClass.LegalCostsDefault(
              4,
              snapshotHasLegalCosts(currentSnapshot),
            ),
          ),
          deepCloneObject(
            ReportSectionClass.LegalCostsDistributionDefault(
              5,
              snapshotHasLegalCosts(currentSnapshot),
            ),
          ),
          deepCloneObject(
            ReportSectionClass.EnforcementRisk(
              6,
              snapshotHasEnforcementRisk(currentSnapshot),
            ),
          ),
          deepCloneObject(ReportSectionClass.SelectionGraphDefault(7)),
          deepCloneObject(
            ReportSectionClass.ProbabilityDistributionGraphDefault(8),
          ),
          deepCloneObject(ReportSectionClass.GetPayGraphDefault(9)),
          deepCloneObject(ReportSectionClass.ExpectedValueBreakdownDefault(10)),
          deepCloneObject(ReportSectionClass.BestWorstScenarioDefault(11)),
        ],
      }
      tempReport.reportScenarioSnapshot = deepCloneObject(currentSnapshot)

      if (freemium.isFreemium) {
        setLoadingReport(false)
        setSavedReport({
          ...savedReport,
          savedReportState: deepCloneObject(tempReport),
          saved: true,
        })
        setReport(tempReport)
        showPdfReport()
        return
      }

      const res = await createReportDetail(
        scenarioIdentity.caseId,
        scenarioIdentity.scenarioId,
        { reportData: tempReport.reportData },
      )
      //Mixpanel 141
      logActivity(false, 'Created pdf report')

      if ('errorCode' in res) {
        setPageDepth(3)
        setLoadingReport(false)
      } else {
        await getWhitelabelReportProperties()

        setLoadingReport(false)
        setSavedReport({
          ...savedReport,
          savedReportState: deepCloneObject(tempReport),
          saved: true,
        })
        tempScenarioIdentity.hasReport = true

        setScenarioIdentity(tempScenarioIdentity)
        setReport(tempReport)
        showPdfReport()
        return
      }
    }
  }

  async function getPdfReport() {
    if (online.networkOn) {
      setLoadingReport(true)
      //Get
      const res = freemium.isFreemium
        ? await getFreemiumReportDetail(
            scenarioIdentity.caseId,
            scenarioIdentity.scenarioId,
          )
        : await getReportDetail(
            scenarioIdentity.caseId,
            scenarioIdentity.scenarioId,
          )
      //Mixpanel 140 (All)
      logActivity(freemium.isFreemium, 'Opened pdf report')
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'getReportDetail'),
      )
      if ('errorCode' in res) {
        setPageDepth(3)
        setLoadingReport(false)
      } else {
        if (!freemium.isFreemium) {
          await getWhitelabelReportProperties()
        }

        setLoadingReport(false)
        let tempReport = deepCloneObject(report)

        tempReport.reportData = adjustClaimsInReport(
          res.data.reportData as ReportDataType,
          currentSnapshot,
        )

        tempReport.reportScenarioSnapshot = res.data.reportSnapshotState.data

        setSavedReport({
          ...savedReport,
          savedReportState: deepCloneObject(tempReport),
          saved: true,
        })
        setReport(tempReport)
        showPdfReport(tempReport)
      }
    }
  }

  return (
    <>
      <div
        className={`resultsOptionsContainer tool-component ${
          props.fixedOptionsLeft ? 'fixed' : ''
        }`}
        id="resultsOptionsContainer"
        style={
          props.fixedOptionsLeft
            ? {
                left: props.fixedOptionsLeft,
                width: `${0.95 * (windowSize.width - 60)}px !important`,
                zIndex: reportMenu.previewPdfOn || loadingReport ? 3 : 3,
              }
            : {
                zIndex: reportMenu.previewPdfOn || loadingReport ? 3 : 3,
              }
        }
      >
        <div className="optionsContainer">
          {props.resultsFromBackend?.reversed && !reportMenu.previewPdfOn && (
            <OptionsComponent
              resultsViewParty={props.resultsViewParty}
              setResultsViewParty={props.setResultsViewParty}
            />
          )}
        </div>
        <Button
          id={'createPdfReportButton'}
          buttonText={
            scenarioIdentity.hasReport
              ? getText('button-40', user.settings)
              : getText('button-41', user.settings)
          }
          buttonType="contained"
          type="button"
          icon={createPdfImg}
          className="createPdfButton"
          onClick={scenarioIdentity.hasReport ? getPdfReport : createPdfReport}
          buttonTextAttribute={
            scenarioIdentity.hasReport ? 'button-40' : 'button-41'
          }
          style={{
            minWidth: scenarioIdentity.hasReport ? 160 : 210,
            height: 44,
          }}
          disabled={props.ownRole === AccessRole.VIEWER}
          side="left"
          NoUpperCase={true}
          small={false}
        />
        {loadingReport ? (
          <div
            className="transparent-createPdr-Background"
            id="transparent-createPdr-Background"
          >
            <div className="circularProgressDiv" id={'popUp7'}>
              <div id={'popUp8'}>
                <CircularProgress size={40} />
              </div>
            </div>
          </div>
        ) : reportMenu.previewPdfOn ? (
          <div
            className="transparent-createPdr-Background"
            id="transparent-createPdr-Background"
          >
            <div
              className="pdfMenuOuterContainer"
              id="pdfMenuOuterContainer"
              style={{ height: heightOfOuterContainer }}
            >
              {reportMenu.orderMenuOn && <SectionOrderMenu />}
              <PreviewPdfReport
                resultsFromBackend={props.resultsFromBackend}
                reportWhitelabel={reportWhitelabel}
              />
            </div>
          </div>
        ) : null}
      </div>
      <ReportChangesMessageComponent
        show={
          reportMenu.reportWithDifferentResults &&
          reportMenu.downloadPdf === false
        }
        text={getText('message-100', user.settings)}
        textAtrribute={'message-100'}
        buttonText={getText('button-65', user.settings)}
        buttonTextAtrribute={'button-65'}
        onClick={() => {
          //Mixpanel 157
          logActivity(false, 'Hid the message that the report has changes')
          setReportMenu({
            ...reportMenu,
            reportWithDifferentResults: false,
          })
        }}
      />
    </>
  )
}
