import { TreeDetails } from '../models/treeModels/treeClaim'
import { EventId, NodeId } from '../models/treeModels/treeTypes'
import { findEventNumber } from './twoParentsFunctionsForNumberOfScenarios'

export function findTreeCombinations(
  nodeToCheck: NodeId,
  treeRootNode: NodeId,
  eventsOfAllNodes: {
    [nodeId: NodeId]: EventId[]
  },
  treeDetails: TreeDetails,
  startTime: number,
  duration: number,
) {
  const eventsOfAllNodesWithNumbers = Object.values(eventsOfAllNodes).map(
    (eventsOfNode) => findEventsOfNodeWithNumbers(eventsOfNode, treeDetails),
  )

  if (performance.now() - startTime > duration) {
    return 'calculation failed'
  }
  let treeCombinations = findTreeCombinationsFromTreeRoot(
    nodeToCheck,
    treeRootNode,
    eventsOfAllNodes,
    eventsOfAllNodesWithNumbers,
    [],
    treeDetails,
    startTime,
    duration,
  )

  if (treeCombinations === 'calculation failed') {
    return 'calculation failed'
  }
  return sortAndFilterUniqueArrays(treeCombinations as number[][])
}

function findTreeCombinationsFromTreeRoot(
  nodeToCheck: NodeId,
  mainTreeNode: NodeId,
  eventsOfAllNodes: {
    [nodeId: NodeId]: EventId[]
  },
  eventsOfAllNodesWithNumbers: number[][],
  treeCombinations: number[][] | string,
  treeDetails: TreeDetails,
  startTime: number,
  duration: number,
): number[][] | string {
  if (typeof treeCombinations === 'string') {
    return 'calculation failed'
  }
  try {
    if (!Object.keys(eventsOfAllNodes).includes(nodeToCheck)) {
      return treeCombinations
    }

    const eventsOfNode = eventsOfAllNodes[nodeToCheck]
    const nodeIndexInEventsOfAllNodes =
      Object.keys(eventsOfAllNodes).indexOf(nodeToCheck)
    const eventsOfNodeWithNumbers =
      eventsOfAllNodesWithNumbers[nodeIndexInEventsOfAllNodes]

    for (let [eventIndexInEventsOfNode, eventId] of eventsOfNode.entries()) {
      if (nodeToCheck === mainTreeNode) {
        const combinationsOfChildren = findCombinationsOfChildren(
          eventId,
          treeDetails,
          eventsOfAllNodes,
        )
        if (typeof treeCombinations !== 'string') {
          treeCombinations = treeCombinations.concat(combinationsOfChildren)
        } else {
          return 'calculation failed'
        }

        for (let childNode of treeDetails.events[eventId].childrenNodes) {
          if (performance.now() - startTime > duration) {
            return 'calculation failed'
          }
          treeCombinations = findTreeCombinationsFromTreeRoot(
            childNode,
            mainTreeNode,
            eventsOfAllNodes,
            eventsOfAllNodesWithNumbers,
            treeCombinations,
            treeDetails,
            startTime,
            duration,
          )
          if (treeCombinations === 'calculation failed') {
            return 'calculation failed'
          }
        }
      } else {
        if (treeDetails.events[eventId].childrenNodes.length > 0) {
          const indexesWithTheEvent = findArrayIndexesWithValue(
            treeCombinations as number[][],
            eventsOfNodeWithNumbers[eventIndexInEventsOfNode],
          )
          let indexesSplicedCounter = 0
          for (let indexWithEvent of indexesWithTheEvent) {
            const combinationsOfChildren =
              replaceArrayWithCombinationOfChildren(
                eventId,
                treeCombinations[
                  indexWithEvent - indexesSplicedCounter
                ] as number[],
                eventsOfAllNodes,
                eventsOfAllNodesWithNumbers,
                treeDetails,
              )
            if (typeof treeCombinations !== 'string') {
              treeCombinations.splice(indexWithEvent - indexesSplicedCounter, 1)
              indexesSplicedCounter += 1
              treeCombinations = treeCombinations.concat(combinationsOfChildren)
            } else {
              return 'calculation failed'
            }
          }

          if (indexesWithTheEvent.length > 0) {
            for (let childNode of treeDetails.events[eventId].childrenNodes) {
              treeCombinations = findTreeCombinationsFromTreeRoot(
                childNode,
                mainTreeNode,
                eventsOfAllNodes,
                eventsOfAllNodesWithNumbers,
                treeCombinations,
                treeDetails,
                startTime,
                duration,
              )
            }
          }
        }
      }
    }
  } catch (error) {
    console.log(error)

    return []
  }

  return treeCombinations
}
export function findArrayIndexesWithValue(
  arrayOfArrays: any[][],
  targetValue: any,
): number[] {
  const indexes: number[] = []

  for (let i = 0; i < arrayOfArrays.length; i++) {
    const subArray = arrayOfArrays[i]
    const subArrayLength = subArray.length

    for (let j = 0; j < subArrayLength; j++) {
      if (subArray[j] === targetValue) {
        indexes.push(i)
        break // Exit the inner loop once the target value is found in the subarray
      }
    }
  }

  return indexes
}

export function sortAndFilterUniqueArrays(
  arrayOfArrays: number[][],
): number[][] {
  // Create a Map to store unique arrays based on a serialized representation
  const uniqueArraysMap = new Map<string, number[]>()

  for (const subArray of arrayOfArrays) {
    // Sort the subarray in place
    subArray.sort((a, b) => a - b)

    // Serialize the sorted subarray to check for uniqueness
    const serialized = subArray.join(',')

    if (!uniqueArraysMap.has(serialized)) {
      uniqueArraysMap.set(serialized, subArray)
    }
  }

  // Extract the unique arrays from the Map
  const uniqueArrays = [...uniqueArraysMap.values()]

  // Sort the unique arrays
  uniqueArrays.sort((a, b) => {
    for (let i = 0; i < Math.min(a.length, b.length); i++) {
      if (a[i] !== b[i]) {
        return a[i] - b[i]
      }
    }
    return a.length - b.length
  })

  return uniqueArrays
}

export function findCombinationsOfChildren(
  eventId: EventId,
  treeDetails: TreeDetails,
  eventsOfAllNodes: {
    [nodeId: NodeId]: EventId[]
  },
): number[][] {
  let eventsOfNodesWithNumbersArray = [
    [findEventNumber(eventId, treeDetails) as number],
  ]
  for (let childNode of treeDetails.events[eventId].childrenNodes) {
    let eventsOfNodeWithNumber: number[] = []
    for (let tempEventId of eventsOfAllNodes[childNode as NodeId]) {
      eventsOfNodeWithNumber.push(
        findEventNumber(tempEventId, treeDetails) as number,
      )
    }
    eventsOfNodesWithNumbersArray.push(eventsOfNodeWithNumber)
  }

  return generatePermutations(eventsOfNodesWithNumbersArray)
}

export function generatePermutations<T>(arrayOfArrays: T[][]): T[][] {
  if (arrayOfArrays.length === 0) {
    return [[]]
  }

  const [firstArray, ...restOfArrays] = arrayOfArrays
  const permutationsOfRest = generatePermutations(restOfArrays)

  const result: T[][] = []
  for (const element of firstArray) {
    for (const perm of permutationsOfRest) {
      result.push([element, ...perm])
    }
  }

  return result
}
function replaceArrayWithCombinationOfChildren(
  eventId: EventId,
  arrayForReplacement: number[],
  eventsOfAllNodes: {
    [nodeId: NodeId]: EventId[]
  },
  eventsOfAllNodesWithNumbers: number[][],
  treeDetails: TreeDetails,
): number[][] {
  if (treeDetails.events[eventId].childrenNodes.length === 0) {
    return [arrayForReplacement]
  }
  const eventsOfNodesWithNumbersArray: any[][] = []

  for (const childNode of treeDetails.events[eventId].childrenNodes) {
    const nodeIndexInEventsOfAllNodes =
      Object.keys(eventsOfAllNodes).indexOf(childNode)

    eventsOfNodesWithNumbersArray.push(
      eventsOfAllNodesWithNumbers[nodeIndexInEventsOfAllNodes],
    )
  }

  let permutationsOfChildren = generatePermutations(
    eventsOfNodesWithNumbersArray,
  )

  for (
    let permutationIndex = 0;
    permutationIndex < permutationsOfChildren.length;
    permutationIndex++
  ) {
    permutationsOfChildren[permutationIndex] = [
      ...arrayForReplacement,
      ...permutationsOfChildren[permutationIndex],
    ]
  }

  // Uncomment the following lines to log the permutations
  // console.log('permutationsOfChildren');
  // console.log(JSON.stringify(permutationsOfChildren));
  permutationsOfChildren = removeSiblingsAndKeepUniques(
    permutationsOfChildren,
    eventsOfAllNodesWithNumbers,
  )

  return permutationsOfChildren
}

function findEventsOfNodeWithNumbers(
  eventsOfNode: EventId[],
  treeDetails: TreeDetails,
) {
  const eventsOfNodeWithNumbers = []
  for (const eventId of eventsOfNode) {
    eventsOfNodeWithNumbers.push(
      findEventNumber(eventId, treeDetails) as number,
    )
  }
  return eventsOfNodeWithNumbers
}

function removeSiblingsAndKeepUniques(
  treeCombinations: number[][],
  eventsOfAllNodesWithNumbers: number[][],
): number[][] {
  const allowedCombinationIndices = new Set<number>()

  for (
    let combinationIndex = treeCombinations.length - 1;
    combinationIndex >= 0;
    combinationIndex--
  ) {
    let isAllowed = true

    for (const eventsOfNodeWithNumbers of eventsOfAllNodesWithNumbers) {
      const intersection = new Set(
        treeCombinations[combinationIndex].filter((item) =>
          eventsOfNodeWithNumbers.includes(item),
        ),
      )

      if (intersection.size >= 2) {
        isAllowed = false
        break
      }
    }

    if (isAllowed) {
      allowedCombinationIndices.add(combinationIndex)
    }
  }

  const allowedCombinations = Array.from(allowedCombinationIndices).map(
    (index) => Array.from(new Set(treeCombinations[index])),
  )

  return allowedCombinations
}
