import React, { useState, useRef, useEffect } from 'react'

import TreeTable from './TreeTable'
import HoverComponent from '../../../../../../../Components/InfoHover/HoverComponent/HoverComponent'

import minimiseTableImg from '../../../../../../../resources/images/228-minimize_table_icon.svg'
import {
  TreeTableInfo,
  TreeAnalysisResults,
  TreeTablesState,
} from '../../../../../../../models/generalTypes'
import noInterestDropdownImg from '../../../../../../../resources/images/276-NoInterestDropdown.svg'
import interestFirstDropdownImg from '../../../../../../../resources/images/277-interestFirstDropdown.svg'
import interestSecondDropdownImg from '../../../../../../../resources/images/278-interestSecondDropdown.svg.svg'
import { TREE_CALCULATION_LIMIT } from '../../../../../../../services/constants'
import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import { checkIfTreeHasInterest } from '../../../../../../../services/resultsFunctions'
import { getText } from '../../../../../../../services/textFunctions'
import {
  TreeClaim,
  TreeDetails,
} from '../../../../../../../models/treeModels/treeClaim'
import {
  EventId,
  NodeId,
} from '../../../../../../../models/treeModels/treeTypes'
import { userState } from '../../../../../../../states/UserState'
import TreeTableWarningPopUp from './TreeTableWarningPopUp'
import {
  findMainTreeAndOrphanTrees,
  findZeroProbabilityEventsInMainTree,
} from '../../../../../../../services/treeFunctions/treeBasicFunctions'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../services/commonFunctions'
import { CircularProgress } from '@mui/material'
import { treeTablesState } from '../../../../../../../states/TreeTablesState'
import TreeSelectionGraphContainer from './TreeSelectionGraphContainer'
import { freemiumState } from '../../../../../../../states/FreemiumState'
import DropDown from '../../../../../../../Components/Inputs/DropDown/DropDown'
import {
  AvailableLanguages,
  InterestViewOption,
  NorwegianInterestViewOption,
  translateInterestViewOption,
} from '../../../../../../../models/enums'
import { valuesIn } from 'lodash'
import ValueOfTree from './ValueOfTree'

type Props = {
  treeIndex: number
  currentTreeTableInfo: TreeTableInfo
  calculationOfTreeArray: TreeAnalysisResults[][] | undefined
  handleShowResultsAfterWhichTrial: (
    interestViewOption: InterestViewOption,
  ) => void
  treeInterestViewOption: InterestViewOption
  sumOfProbs: number
  selectedPath: number[]
  activeRow: number | undefined
  setActiveRow: (param: number | undefined) => void
  treeDirectCalculation: () => void
  setSelectedPath: (param: number[]) => void
  fixed: boolean
  orphanTrees: NodeId[][]
  treeDetails: TreeDetails
  openOrphanNodesWarningPopUp: boolean
  setOpenOrphanNodesWarningPopUp: (openOrphanNodesWarningPopUp: boolean) => void
  openZeroProbabilityWarningPopUp: boolean
  setOpenZeroProbabilityWarningPopUp: (
    openZeroProbabilityWarningPopUp: boolean,
  ) => void
  checkIfTreeHasErrors: (treeIndex: number) => boolean
  treeWarningsIndex: number
  addCheckedIndex: (value: number) => void
}

export default function TreeTableContainer(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  const [hoverElement, setHoverElement] = useState('')
  const secondaryHover = useRef('open')
  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [orphanNodeIndex, setOrphanNodeIndex] = useState(0)
  const [zeroProbabilityIndex, setZeroProbabilityIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)

  const interestViewOption =
    user.settings.language === AvailableLanguages.norwegian
      ? NorwegianInterestViewOption
      : InterestViewOption

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  useEffect(() => {
    setOrphanNodeIndex(0)
    if (props.orphanTrees.length === 0) {
      props.setOpenOrphanNodesWarningPopUp(false)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [props.orphanTrees.length])

  useEffect(() => {
    setZeroProbabilityIndex(0)
    if (
      findZeroProbabilityEventsInMainTree(
        props.treeDetails,
        props.orphanTrees.flat(),
      ).length === 0
    ) {
      props.setOpenZeroProbabilityWarningPopUp(false)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    findZeroProbabilityEventsInMainTree(
      props.treeDetails,
      props.orphanTrees.flat(),
    ).length,
  ])

  useEffect(() => {
    setZeroProbabilityIndex(0)
    if (props.treeWarningsIndex === props.treeIndex) {
      checkForOrphanNodes()
    }
    // eslint-disable-next-line
  }, [props.treeWarningsIndex, props.treeIndex])

  useEffect(() => {
    if (
      props.calculationOfTreeArray &&
      props.calculationOfTreeArray.length > 0
    ) {
      let firstNonZeroProbScenario = props.calculationOfTreeArray[0].findIndex(
        (scenario) => scenario[5] !== 0,
      )
      if (firstNonZeroProbScenario !== -1) {
        props.setActiveRow(1)
        props.setSelectedPath(
          JSON.parse(
            props.calculationOfTreeArray[0][firstNonZeroProbScenario][0],
          ),
        )
      }
    } else {
      props.setActiveRow(undefined)
      props.setSelectedPath([])
    }
    // eslint-disable-next-line
  }, [props.treeIndex])

  function checkForOrphanNodes() {
    setLoading(true)

    setTimeout(() => {
      let tempOrphanTrees = props.orphanTrees

      if (props.treeWarningsIndex === props.treeIndex) {
        const [, , orphanTrees] = findMainTreeAndOrphanTrees(
          deepCloneObject(props.treeDetails),
        )

        if (orphanTrees.includes(['node123'])) return
        tempOrphanTrees = deepCloneObject(orphanTrees)
      }

      if (tempOrphanTrees.length > 0) {
        navigateToNextOrphanRoot(tempOrphanTrees)
        props.setOpenOrphanNodesWarningPopUp(true)
      } else {
        checkForZeroProbabilitiesEvents(tempOrphanTrees)
      }
    }, 50)
  }

  function checkForZeroProbabilitiesEvents(tempOrphanTrees?: NodeId[][]) {
    props.setOpenOrphanNodesWarningPopUp(false)
    if (
      findZeroProbabilityEventsInMainTree(
        props.treeDetails,
        tempOrphanTrees ? tempOrphanTrees.flat() : props.orphanTrees.flat(),
      ).length > 0
    ) {
      navigateToNextZeroProbabilityNode(tempOrphanTrees)
      props.setOpenZeroProbabilityWarningPopUp(true)
    } else {
      if (props.treeWarningsIndex === props.treeIndex) {
        props.addCheckedIndex(props.treeIndex)
      } else {
        setLoading(false)
        props.treeDirectCalculation()
      }
      props.setSelectedPath([])
    }
  }

  function ignoreOrphanNodes() {
    //Mixpanel 125 (All)
    logActivity(freemium.isFreemium, 'Ignored floating nodes in a legal tree')
    checkForZeroProbabilitiesEvents()
    props.setOpenOrphanNodesWarningPopUp(false)
  }

  function ignoreZeroProbability() {
    //Mixpanel 126 (All)
    logActivity(freemium.isFreemium, 'Ignored 0% nodes in a legal tree')

    props.setOpenZeroProbabilityWarningPopUp(false)
    if (props.treeWarningsIndex === props.treeIndex) {
      props.addCheckedIndex(props.treeIndex)
    } else {
      setLoading(false)
      props.treeDirectCalculation()
    }
    props.setSelectedPath([])
  }

  function navigateToNextOrphanRoot(tempOrphanTrees?: NodeId[][]) {
    if (
      document.getElementById(
        `treeNodeTitleTextarea-${props.treeIndex}_${
          tempOrphanTrees
            ? tempOrphanTrees[orphanNodeIndex][0]
            : props.orphanTrees[orphanNodeIndex][0]
        }`,
      )
    ) {
      document
        .getElementById(
          `treeNodeTitleTextarea-${props.treeIndex}_${
            tempOrphanTrees
              ? tempOrphanTrees[orphanNodeIndex][0]
              : props.orphanTrees[orphanNodeIndex][0]
          }`,
        )!
        .focus()
      document
        .getElementById(
          `treeNodeTitleTextarea-${props.treeIndex}_${
            tempOrphanTrees
              ? tempOrphanTrees[orphanNodeIndex][0]
              : props.orphanTrees[orphanNodeIndex][0]
          }`,
        )
        ?.blur()
      let tempOrphanNodeIndex = orphanNodeIndex + 1
      if (tempOrphanNodeIndex >= props.orphanTrees.length) {
        tempOrphanNodeIndex = 0
      }
      setOrphanNodeIndex(tempOrphanNodeIndex)
    }
    //Mixpanel 123 (All)
    logActivity(
      freemium.isFreemium,
      'Navigated to the next floating node in a legal tree',
    )
  }

  function navigateToNextZeroProbabilityNode(tempOrphanTrees?: NodeId[][]) {
    const nodeId =
      props.treeDetails.events[
        findZeroProbabilityEventsInMainTree(
          props.treeDetails,
          tempOrphanTrees ? tempOrphanTrees.flat() : props.orphanTrees.flat(),
        )[zeroProbabilityIndex] as EventId
      ].nodeOfEventId

    if (
      document.getElementById(
        `treeNodeTitleTextarea-${props.treeIndex}_${nodeId}`,
      )
    ) {
      document
        .getElementById(`treeNodeTitleTextarea-${props.treeIndex}_${nodeId}`)!
        .focus()
      document
        .getElementById(`treeNodeTitleTextarea-${props.treeIndex}_${nodeId}`)!
        .blur()

      let tempZeroProbabilityIndex = zeroProbabilityIndex + 1
      if (
        tempZeroProbabilityIndex >=
        findZeroProbabilityEventsInMainTree(
          props.treeDetails,
          tempOrphanTrees ? tempOrphanTrees.flat() : props.orphanTrees.flat(),
        ).length
      ) {
        tempZeroProbabilityIndex = 0
      }
      setZeroProbabilityIndex(tempZeroProbabilityIndex)
    }
    //Mixpanel 124 (All)
    logActivity(
      freemium.isFreemium,
      'Navigated to the next 0% node in a legal tree',
    )
  }

  return (
    <>
      <div
        className="treeResultsContainerBackground"
        style={props.fixed ? { position: 'fixed', top: 25, right: 60 } : {}}
        id={`treeResultsContainerBackground-${props.treeIndex}`}
      >
        {props.currentTreeTableInfo?.showTreeTable &&
        props.calculationOfTreeArray ? (
          <div className="treeResultsContainer">
            <div className="treeResultsTitleContainer">
              <p
                className="treeResultsTitleText"
                id={`treeTableTitleText-${props.treeIndex}`}
                data-textattribute="title-47"
              >
                {getText('title-47', user.settings)}
              </p>

              <DropDown
                id={`treeInterestViewDropDown-${props.treeIndex}`}
                label={getText('label-94', user.settings)}
                labelTextAttribute={'label-94'}
                name={`treeInterestViewDropDown-${props.treeIndex}`}
                value={translateInterestViewOption(
                  props.currentTreeTableInfo.treeInterestViewOption,
                  AvailableLanguages.english,
                  user.settings.language,
                )}
                width={'180px'}
                height={'33px'}
                options={
                  scenarioSnapshot.currentSnapshot.secondTrialDate
                    ? [...valuesIn(interestViewOption)]
                    : [...valuesIn(interestViewOption)].filter(
                        (value) => value !== interestViewOption.interest2nd,
                      )
                }
                onChange={(e: any) => {
                  props.handleShowResultsAfterWhichTrial(
                    translateInterestViewOption(
                      e.target.value,
                      user.settings.language,
                      AvailableLanguages.english,
                    ) as InterestViewOption,
                  )
                }}
                interestDropdownImg={[
                  noInterestDropdownImg,
                  interestFirstDropdownImg,
                  interestSecondDropdownImg,
                ]}
                disabled={
                  checkIfTreeHasInterest(
                    props.calculationOfTreeArray[0],
                    scenarioSnapshot.currentSnapshot,
                  )
                    ? undefined
                    : true
                }
                disabledMessage=""
              />
            </div>

            <TreeTable
              //We exclude paths with 0% probability from the results
              calculationOfTreeArray={
                props.treeInterestViewOption !== InterestViewOption.interest2nd
                  ? props.calculationOfTreeArray[0]
                  : props.calculationOfTreeArray[1]
              }
              sumOfProbs={props.sumOfProbs}
              setSelectedPath={props.setSelectedPath}
              selectedPath={props.selectedPath}
              activeRow={props.activeRow}
              setActiveRow={props.setActiveRow}
              treeInterestViewOption={props.treeInterestViewOption}
              treeIndex={props.treeIndex}
            />

            <ValueOfTree
              treeIndex={props.treeIndex}
              currentTreeTableInfo={props.currentTreeTableInfo}
              calculationOfTreeArray={
                props.treeInterestViewOption !== InterestViewOption.interest2nd
                  ? props.calculationOfTreeArray[0].filter(
                      (results) => results[5] !== 0,
                    )
                  : props.calculationOfTreeArray[1].filter(
                      (results) => results[5] !== 0,
                    )
              }
            />

            <TreeSelectionGraphContainer
              treeIndex={props.treeIndex}
              currentTreeTableInfo={props.currentTreeTableInfo}
            />
          </div>
        ) : null}
        {props.openOrphanNodesWarningPopUp && props.orphanTrees.length > 0 ? (
          <TreeTableWarningPopUp
            treeIndex={props.treeIndex}
            id={'orphanNodes'}
            message={
              <p
                className="warningPopUpMessage"
                id={`orphanNodeswarningPopUpMessage-${props.treeIndex}`}
                data-textattribute={'message-91a, message-91c'}
              >
                {getText('message-91a', user.settings)}{' '}
                <span
                  className="warningPopUpMessageSpan"
                  id={`orphanNodeswarningPopUpMessageSpan-${props.treeIndex}`}
                  data-textattribute={'message-91b'}
                  onClick={() => {
                    navigateToNextOrphanRoot()
                  }}
                >
                  {getText('message-91b', user.settings)} (
                  <strong>{props.orphanTrees.length}</strong>)
                </span>{' '}
                {getText('message-91c', user.settings)}
              </p>
            }
            buttonText={getText('button-124', user.settings)}
            buttonTextAttribute={'button-124'}
            onClick={ignoreOrphanNodes}
            onCancel={() => {
              props.addCheckedIndex(-1)
              setLoading(false)
              props.setOpenOrphanNodesWarningPopUp(false)
            }}
          />
        ) : props.openZeroProbabilityWarningPopUp ? (
          <TreeTableWarningPopUp
            treeIndex={props.treeIndex}
            id={'zeroProbability'}
            message={
              <p
                className="warningPopUpMessage"
                id={`zeroProbabilitywarningPopUpMessage-${props.treeIndex}`}
                data-textattribute={'message-92a, message-92c, message-92d'}
              >
                {getText('message-92a', user.settings)}{' '}
                <span
                  className="warningPopUpMessageSpan"
                  id={`zeroProbabilitywarningPopUpMessageSpan-${props.treeIndex}`}
                  data-textattribute={'message-92b'}
                  onClick={() => {
                    navigateToNextZeroProbabilityNode()
                  }}
                >
                  {getText('message-92b', user.settings)} (
                  <strong>
                    {
                      findZeroProbabilityEventsInMainTree(
                        props.treeDetails,
                        props.orphanTrees.flat(),
                      ).length
                    }
                  </strong>
                  )
                </span>{' '}
                {getText('message-92c', user.settings)}{' '}
                <strong>{getText('message-92d', user.settings)}</strong>.
              </p>
            }
            buttonText={getText('button-31b', user.settings)}
            buttonTextAttribute={'button-31b'}
            onClick={ignoreZeroProbability}
            onCancel={() => {
              props.addCheckedIndex(-1)
              setLoading(false)
              props.setOpenZeroProbabilityWarningPopUp(false)
            }}
          />
        ) : (
          <div
            className={
              props.currentTreeTableInfo?.showTreeTable &&
              props.calculationOfTreeArray
                ? 'showTreeResultsButtonContainer minimise'
                : 'showTreeResultsButtonContainer'
            }
          >
            {props.currentTreeTableInfo?.showTreeTable &&
            props.calculationOfTreeArray &&
            props.calculationOfTreeArray.length > 0 ? (
              <div
                className="showTreeResultsButton minimiseTreeResultsButton showResults"
                onClick={() => {
                  //hide/show TreeTable
                  const treeTableInfoIndex =
                    treeTables.treeTablesInfo.findIndex(
                      (treeTableInfo) =>
                        treeTableInfo.treeId ===
                        scenarioSnapshot.currentSnapshot.claims[props.treeIndex]
                          .id,
                    )

                  let tempTreeTables: TreeTablesState =
                    deepCloneObject(treeTables)

                  if (
                    tempTreeTables.treeTablesInfo &&
                    tempTreeTables.treeTablesInfo[treeTableInfoIndex]
                  ) {
                    tempTreeTables.treeTablesInfo[
                      treeTableInfoIndex
                    ].showTreeTable =
                      !tempTreeTables.treeTablesInfo[treeTableInfoIndex]
                        .showTreeTable

                    setTreeTables(tempTreeTables)
                  }
                }}
              >
                <img
                  src={minimiseTableImg}
                  alt="minimiseTable"
                  className="minimiseTableTreeResultsButton"
                />
              </div>
            ) : props.calculationOfTreeArray &&
              props.calculationOfTreeArray.length > 0 ? (
              <>
                <div
                  className="showTreeResultsButton minimiseTreeResultsButton"
                  onClick={() => {
                    //hide/show TreeTable
                    const treeTableInfoIndex =
                      treeTables.treeTablesInfo.findIndex(
                        (treeTableInfo) =>
                          treeTableInfo.treeId ===
                          scenarioSnapshot.currentSnapshot.claims[
                            props.treeIndex
                          ].id,
                      )

                    let tempTreeTables: TreeTablesState =
                      deepCloneObject(treeTables)

                    if (
                      tempTreeTables.treeTablesInfo &&
                      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
                    ) {
                      tempTreeTables.treeTablesInfo[
                        treeTableInfoIndex
                      ].showTreeTable =
                        !tempTreeTables.treeTablesInfo[treeTableInfoIndex]
                          .showTreeTable

                      setTreeTables(tempTreeTables)
                    }
                  }}
                >
                  <img
                    src={minimiseTableImg}
                    alt="minimiseTable"
                    className="minimiseTableTreeResultsButton"
                    style={{ transform: 'rotate(180deg)' }}
                  />
                </div>
                <div
                  className="showTreeResultsButton"
                  style={{
                    borderRadius: '5px',
                    boxShadow: '0px 3px 6px #00000029',
                  }}
                  onClick={() => {
                    //hide/show TreeTable
                    const treeTableInfoIndex =
                      treeTables.treeTablesInfo.findIndex(
                        (treeTableInfo) =>
                          treeTableInfo.treeId ===
                          scenarioSnapshot.currentSnapshot.claims[
                            props.treeIndex
                          ].id,
                      )

                    let tempTreeTables: TreeTablesState =
                      deepCloneObject(treeTables)

                    if (
                      tempTreeTables.treeTablesInfo &&
                      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
                    ) {
                      tempTreeTables.treeTablesInfo[
                        treeTableInfoIndex
                      ].showTreeTable =
                        !tempTreeTables.treeTablesInfo[treeTableInfoIndex]
                          .showTreeTable

                      setTreeTables(tempTreeTables)
                    }
                  }}
                >
                  <p
                    id="possibleOutcomesButtonText"
                    data-textattribute="button-85"
                  >
                    {getText('button-85', user.settings)}
                  </p>
                </div>
              </>
            ) : (
              <div
                className="calculateTreeButtonContainer"
                onMouseEnter={() => {
                  secondaryHover.current = 'calculateTreeButton'
                  setTimeout(() => {
                    if (secondaryHover.current === 'calculateTreeButton') {
                      setHoverElement('calculateTreeButton')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                {hoverElement === 'calculateTreeButton' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '50px' }}
                  >
                    <HoverComponent
                      hoverText={getText('hover-22', user.settings)}
                      textAttribute="hover-22"
                      id="calculateTreeButton"
                    />
                  </div>
                ) : null}
                {(
                  scenarioSnapshot.currentSnapshot.claims[
                    props.treeIndex
                  ] as TreeClaim
                ).numOfTreeScenarios &&
                (
                  scenarioSnapshot.currentSnapshot.claims[
                    props.treeIndex
                  ] as TreeClaim
                ).numOfTreeScenarios >= TREE_CALCULATION_LIMIT ? (
                  <button
                    type="button"
                    className="calculateTreeButton small treeLimitReachedButton"
                    onClick={() => {
                      setLoading(false)
                      setHoverElement('')
                      props.treeDirectCalculation()
                    }}
                  >
                    <p
                      className="calculateTreeButtonText"
                      id="calculateTreeButtonTextExceeded"
                      data-textattribute="button-30"
                    >
                      {getText('button-30', user.settings)}
                    </p>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="calculateTreeButton"
                    onClick={() => {
                      if (loading) return

                      setHoverElement('')
                      const treeHasErrors = props.checkIfTreeHasErrors(
                        props.treeIndex,
                      )
                      if (!treeHasErrors) checkForOrphanNodes()
                    }}
                    id={`calculateTreeButton-${props.treeIndex}`}
                  >
                    {loading ? (
                      <CircularProgress size={25} />
                    ) : (
                      <p
                        className="calculateTreeButtonText"
                        id={`calculateTreeButtonText-${props.treeIndex}`}
                        data-textattribute={'button-31b'}
                      >
                        {getText('button-31b', user.settings)}
                      </p>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
