import React from 'react'

import {
  ResultsObject,
  ResultsSettings,
} from '../../../../../../models/generalTypes'
import ValueBreakdownGraphContainer from './ValueBreakdownGraphContainer'
import { userState } from '../../../../../../states/UserState'
import { useRecoilValue } from 'recoil'
import { stringAndRoundBigNumbers } from '../../../../../../Modules/DisputeModules/AppFunctionsNew'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import { ReportWhitelabel } from '../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'
import { breakDownValueFontSize } from '../../../../../../services/commonFunctions'
import useWindowSize from '../../../../../../customHooks/useWindowSize'
import { getText } from '../../../../../../services/textFunctions'
import { ScenarioSnapshot } from '../../../../../../models/scenarioSnapshot'
import { includedIncurredCosts } from '../../../../../../services/resultsFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  resultsSettings: ResultsSettings
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
  resultsViewParty: 'client' | 'opposing'
  resultsSnapshot: ScenarioSnapshot
}

export default function ExpectedValueBreakdownForReport(props: Props) {
  const user = useRecoilValue(userState)
  const { width } = useWindowSize()

  return (
    <div
      className={`expectedValueBreakdownGraphContainer ${
        props.pdfPreview ? 'forReport' : ''
      }`}
    >
      <div
        className={`expectedValueBreakdownContainer tab-content ${
          props.pdfPreview ? 'forReport' : ''
        }`}
        id={`expectedValueBreakdownContainer${
          props.pdfPreview ? '-forReport' : ''
        }`}
        data-openreplay-obscured
      >
        <ValueBreakdownGraphContainer
          resultsFromBackend={props.resultsFromBackend}
          type={'positive'}
          resultsSettings={props.resultsSettings}
          pdfPreview={props.pdfPreview}
          reportWhitelabel={props.reportWhitelabel}
          resultsViewParty={props.resultsViewParty}
        />
        <ValueBreakdownGraphContainer
          resultsFromBackend={props.resultsFromBackend}
          type={'negative'}
          resultsSettings={props.resultsSettings}
          pdfPreview={props.pdfPreview}
          reportWhitelabel={props.reportWhitelabel}
          resultsViewParty={props.resultsViewParty}
        />
        <div
          className="valueBreakdownContainer"
          id={`valueBreakdownContainer-expectedValue${
            props.pdfPreview ? '-forReport' : ''
          }`}
        >
          <div className="valueBreakdownComponent">
            <div className="valueBreakdownTextContainer">
              <p
                className="valueBreakdownText expectedValue"
                id={`valueBreakdownText-expectedValue${
                  props.pdfPreview ? '-forReport' : ''
                }`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.medium,
                  'noRoboto',
                )}
                data-textattribute="title-275"
              >
                {getText('title-275', user.settings)}
              </p>
            </div>
            <div
              className={`valueBreakdownGraph expectedValue`}
              id={`valueBreakdownGraph-expectedValue${
                props.pdfPreview ? '-forReport' : ''
              }`}
            >
              <div
                className="breakdownValuesContainer"
                id={`breakdownValuesContainer-expectedValue${
                  props.pdfPreview ? '-forReport' : ''
                }`}
              >
                <p
                  className={`breakdownValue expectedValue robotoNumbers`}
                  key={`breakdownValue-expectedValue`}
                  id={`breakdownValue-expectedValue${
                    props.pdfPreview ? '-forReport' : ''
                  }`}
                  style={{
                    ...getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.medium,
                      'roboto',
                    ),
                    ...breakDownValueFontSize(
                      `${stringAndRoundBigNumbers(
                        props.resultsFromBackend.value_of_claim,
                        user.settings,
                        props.resultsSettings.settings.roundedResults,
                      )}${
                        props.resultsFromBackend.model_used === 'statistical'
                          ? '*'
                          : ''
                      } ${props.resultsSnapshot.currency}`,
                      width,
                    ),
                  }}
                >
                  {stringAndRoundBigNumbers(
                    props.resultsFromBackend.value_of_claim +
                      includedIncurredCosts(
                        'client',
                        'both',
                        props.resultsSettings.settings.includeIncurredCosts,
                        props.resultsSnapshot,
                      ),
                    user.settings,
                    props.resultsSettings.settings.roundedResults,
                  )}
                  {props.resultsFromBackend.model_used === 'statistical'
                    ? '*'
                    : ''}{' '}
                  {props.resultsSnapshot.currency}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
