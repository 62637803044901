import React, { useState, useRef, useEffect } from 'react'

import ExecutiveSummary from './resultsComponents/1_executiveSummry/ExecutiveSummary'
import Graph from './resultsComponents/2_graphs/Graph'
import DetailedResults from './resultsComponents/3_detailedResults/DetailedResults'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import ProceedingsPlaceholder from '../1_proceedings/ProceedingsPlaceholder'
import { scenarioIdentityState } from '../../../../states/ScenarioIdentityState'
import { deepCloneObject } from '../../../../services/commonFunctions'
import ResultsOptionsContainer from './resultsComponents/5_pdfReport/ResultsOptionsContainer'
import { ResultsObject } from '../../../../models/generalTypes'
import { reportMenuState } from '../../../../states/ReportMenuState'
import { AccessRole, VideoPostName } from '../../../../models/enums'
import FullScreenPopMessage from '../../../../Components/popUps/FullScreenPopMessage'
import { userState } from '../../../../states/UserState'
import sampleImg from '../../../../resources/images/243-sampleFinder.svg'
import { getText } from '../../../../services/textFunctions'
import { reverseCase } from '../../../../services/reverseCaseFunctions'
import { stringAndRoundBigNumbers } from '../../../../Modules/DisputeModules/AppFunctionsNew'
import { resultsSettingsState } from '../../../../states/ResultsSettingsState'
import { getResultsSettings } from '../../../../services/requests'
import { freemiumState } from '../../../../states/FreemiumState'
import ExpectedValueBreakdownForResults from './resultsComponents/4_expectedValueBreakdown/ExpectedValueBreakdownForResults'
import {
  calculateIncurredCosts,
  checkIfResultsAreOutdated,
} from '../../../../services/resultsFunctions'
import Button from '../../../../Components/Buttons/Button/Button'
import lampImg from '../../../../resources/images/318-lamp.svg'

type Props = {
  setVideoPostName: (param: VideoPostName | undefined) => void
  ownRole: AccessRole
  openInOtherTab: boolean
}
export default function Results(props: Props) {
  const [resultsFromBackend, setResultsFromBackend] = useState<
    ResultsObject | undefined
  >(undefined)

  const [showAWADetails, setShowAWADetails] = useState(false)
  const [showSettlementDetails, setShowSettlementDetails] = useState(false)
  const [loadingResults, setLoadingResults] = useState(true)
  const [loadingResultsSettings, setLoadingResultsSettings] = useState(true)
  const [hasNewFeatures, setHasNewFeatures] = useState(false)

  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const freemium = useRecoilValue(freemiumState)
  const reverseSnapshot = reverseCase(scenarioSnapshot.currentSnapshot)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const user = useRecoilValue(userState)
  const [resultsSettings, setResultsSettings] =
    useRecoilState(resultsSettingsState)

  const componentRef = useRef<HTMLDivElement>(null)
  const [widthOfDiv, setWidthOfDiv] = useState(0)
  const [heightOfDiv, setHeightOfDiv] = useState(0)
  const reportMenu = useRecoilValue(reportMenuState)

  const [totalIncurredLegalCosts, setTotalIncurredLegalCosts] = useState(0)
  const [settlementAmount, setSettlementAmount] = useState(0)
  const [resultsViewParty, setResultsViewParty] = useState<
    'client' | 'opposing'
  >('client')

  const resultsSnapshot =
    resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot
      : reverseSnapshot

  const [fixedOptionsLeft, setFixedOptionsLeft] = useState<number | undefined>(
    undefined,
  )

  useEffect(() => {
    if (freemium.isFreemium) {
      setResultsSettings({
        settings: { ...resultsSettings.settings, roundedResults: false },
        scenarioId: '',
      })
      setLoadingResultsSettings(false)
      return
    }
    if (resultsSettings.scenarioId !== scenarioIdentity.scenarioId) {
      getResultsSettings(
        scenarioIdentity.caseId,
        scenarioIdentity.scenarioId,
      ).then((res: any) => {
        setLoadingResultsSettings(false)
        if (res && res.data !== undefined && res.data.settings) {
          let tempSettings = res.data.settings
          if (tempSettings.selectionGraphSettings === undefined) {
            tempSettings.selectionGraphSettings = {
              position: { client: [0.2, 0.4], opposing: [0.2, 0.4] },
              showRanges: true,
            }
          }

          setResultsSettings({
            settings: tempSettings,
            scenarioId: res.data.case,
          })
        }
      })
    } else {
      setLoadingResultsSettings(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (scenarioIdentity.results !== undefined) {
      let array: any[] = []
      if (scenarioIdentity.results.table.length > 0) {
        scenarioIdentity.results.table.map((result, index) => {
          array[index] = [
            index,
            result.percentage_of_total_claim,
            result.amount_received,
            result.legal_fees_covered_by_other,
          ]
          return []
        })
      }

      let array2: any[] = []
      if (
        scenarioIdentity.results.reversed &&
        scenarioIdentity.results.reversed.table.length > 0
      ) {
        scenarioIdentity.results.reversed.table.map((result, index) => {
          array2[index] = [
            index,
            result.percentage_of_total_claim,
            result.amount_received,
            result.legal_fees_covered_by_other,
          ]
          return []
        })
      }
      deepCloneObject(scenarioIdentity.results)
      setResultsFromBackend(deepCloneObject(scenarioIdentity.results))
      // console.log(JSON.stringify(scenarioIdentity.results))
      // console.log(JSON.stringify(scenarioSnapshot.currentSnapshot))

      setHasNewFeatures(checkIfResultsAreOutdated(scenarioIdentity.results))
      setTimeout(() => {
        setLoadingResults(false)
      }, 10)
    }

    // eslint-disable-next-line
  }, [scenarioIdentity.results])

  useEffect(() => {
    let tempTotalIncurredLegalCosts = calculateIncurredCosts(
      resultsSnapshot.legalCosts,
      resultsSnapshot.hasSecondTrial,
    ).total_Client_IncurredLegalCosts

    setTotalIncurredLegalCosts(tempTotalIncurredLegalCosts)
    if (resultsFromBackend) {
      if (resultsViewParty === 'client' || !resultsFromBackend.reversed) {
        setSettlementAmount(
          resultsFromBackend.settlement_amount ??
            resultsFromBackend.value_of_claim,
        )
      } else {
        setSettlementAmount(
          resultsFromBackend.reversed.settlement_amount ??
            resultsFromBackend.reversed.value_of_claim,
        )
      }
    }
    // eslint-disable-next-line
  }, [resultsFromBackend, resultsViewParty])

  useEffect(() => {
    if (componentRef.current) {
      setWidthOfDiv(componentRef.current.offsetWidth)
      setHeightOfDiv(componentRef.current.offsetWidth / 2)
    }
    // eslint-disable-next-line
  }, [window.innerWidth, loadingResults])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
    }
    // eslint-disable-next-line
  }, [window])

  const onScroll = (e: any) => {
    const eReportCompElement = document.getElementById('eReportComp')
    if (eReportCompElement) {
      const distanceFromTop = eReportCompElement.getBoundingClientRect().top
      const distanceFromLeft = eReportCompElement.getBoundingClientRect().left

      if (distanceFromTop < 133) {
        setFixedOptionsLeft(distanceFromLeft)
      } else {
        setFixedOptionsLeft(undefined)
      }
    }
  }

  return (
    <>
      {loadingResults ? (
        <ProceedingsPlaceholder />
      ) : hasNewFeatures ? (
        <div className="hasNewFaturesOuterContainer">
          <div className="hasNewFeaturesContainer">
            <img src={lampImg} alt="lampImg" className="newFeaturesImg" />
            <p className="hasNewFeaturesTitle" data-textattribute="title-274">
              {getText('title-274', user.settings)}
            </p>
            <p
              className="hasNewFeaturesText"
              data-textattribute="description-278"
            >
              {getText('description-278', user.settings)}
            </p>
            <div className="hasNewFeaturesButtonContainer">
              <Button
                buttonText={'RECALCULATE'}
                buttonTextAttribute={'button-00'}
                buttonType={'contained'}
                id={'recalculateButton'}
                type={'submit'}
                side={'middle'}
                NoUpperCase={false}
                small={false}
                style={{ width: 276 }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="homescreen"
          id="resultsScreenContainer"
          ref={componentRef}
          style={{ overflowY: 'hidden', overflowX: 'hidden' }}
        >
          <ResultsOptionsContainer
            resultsFromBackend={resultsFromBackend}
            ownRole={props.ownRole}
            resultsViewParty={resultsViewParty}
            setResultsViewParty={setResultsViewParty}
            fixedOptionsLeft={fixedOptionsLeft}
            setFixedOptionsLeft={setFixedOptionsLeft}
          />
          {reportMenu.orderMenuOn ? null : (
            <>
              <ExecutiveSummary
                resultsFromBackend={resultsFromBackend}
                setVideoPostName={props.setVideoPostName}
                setShowAWADetails={setShowAWADetails}
                setShowSettlementDetails={setShowSettlementDetails}
                resultsViewParty={resultsViewParty}
                fixedOptionsLeft={fixedOptionsLeft}
              />

              <Graph
                resultsFromBackend={
                  resultsViewParty === 'opposing' &&
                  resultsFromBackend!.reversed
                    ? resultsFromBackend!.reversed
                    : resultsFromBackend!
                }
                resultsSnapshot={resultsSnapshot}
                typeOfGraph="selectionGraph"
                widthOfDiv={widthOfDiv}
                heightOfDiv={heightOfDiv}
                setVideoPostName={props.setVideoPostName}
                setShowAWADetails={setShowAWADetails}
                resultsViewParty={resultsViewParty}
                loadingResultsSettings={loadingResultsSettings}
              />
              <Graph
                resultsFromBackend={
                  resultsViewParty === 'opposing' &&
                  resultsFromBackend!.reversed
                    ? resultsFromBackend!.reversed
                    : resultsFromBackend!
                }
                resultsSnapshot={resultsSnapshot}
                typeOfGraph="distributionGraph"
                widthOfDiv={widthOfDiv}
                heightOfDiv={heightOfDiv}
                setVideoPostName={props.setVideoPostName}
                setShowAWADetails={setShowAWADetails}
                resultsViewParty={resultsViewParty}
              />
              <Graph
                resultsFromBackend={
                  resultsViewParty === 'opposing' &&
                  resultsFromBackend!.reversed
                    ? resultsFromBackend!.reversed
                    : resultsFromBackend!
                }
                resultsSnapshot={resultsSnapshot}
                typeOfGraph="getPayGraph"
                widthOfDiv={widthOfDiv}
                heightOfDiv={heightOfDiv}
                setVideoPostName={props.setVideoPostName}
                setShowAWADetails={setShowAWADetails}
                resultsViewParty={resultsViewParty}
              />

              <ExpectedValueBreakdownForResults
                resultsFromBackend={
                  resultsViewParty === 'opposing' &&
                  resultsFromBackend!.reversed
                    ? resultsFromBackend!.reversed
                    : resultsFromBackend!
                }
                resultsSnapshot={resultsSnapshot}
                setShowAWADetails={setShowAWADetails}
                resultsViewParty={resultsViewParty}
              />

              <DetailedResults
                resultsFromBackend={
                  resultsViewParty === 'opposing' &&
                  resultsFromBackend!.reversed
                    ? resultsFromBackend!.reversed
                    : resultsFromBackend!
                }
                resultsSnapshot={resultsSnapshot}
                widthOfDiv={widthOfDiv}
                heightOfDiv={heightOfDiv}
                setVideoPostName={props.setVideoPostName}
                resultsViewParty={resultsViewParty}
              />
            </>
          )}

          {showAWADetails ? (
            <FullScreenPopMessage
              id="AWADetails"
              zIndex={1900}
              backGroundColor="#6f6f6f" //tuesdayGray
              blur
              titleTextAttribute="title-221"
              warningTitle={getText('title-221', user.settings)}
              warningTextContainer={
                <>
                  <img
                    src={sampleImg}
                    alt="sampleImg"
                    className={'statusImage'}
                  />

                  <p
                    className="warningText"
                    id="popUpWarningText-AWADetails1"
                    data-textattribute="description-173"
                    style={{
                      width: 750,
                      fontSize: 16,
                      lineHeight: 1.2,
                    }}
                  >
                    {getText('description-173', user.settings)}
                  </p>
                  <span style={{ height: 15 }}></span>
                  <p
                    className="warningText"
                    id="popUpWarningText-AWADetails2"
                    data-textattribute="description-174"
                    style={{ width: 750, fontSize: 16, lineHeight: 1.2 }}
                  >
                    {getText('description-174', user.settings)}
                  </p>
                  <span style={{ height: 15 }}></span>
                  <p
                    className="warningText"
                    id="popUpWarningText-AWADetails2"
                    data-textattribute="description-174b"
                    style={{ width: 750, fontSize: 16, lineHeight: 1.2 }}
                  >
                    {getText('description-174b', user.settings)}
                  </p>
                  <span style={{ height: 15 }}></span>
                  <p
                    className="warningText"
                    id="popUpWarningText-AWADetails2"
                    data-textattribute="description-174c"
                    style={{ width: 750, fontSize: 16, lineHeight: 1.2 }}
                  >
                    {getText('description-174c', user.settings)}
                  </p>
                </>
              }
              cancelFunction={() => setShowAWADetails(false)}
            />
          ) : null}

          {showSettlementDetails ? (
            <FullScreenPopMessage
              id="SettlementDetails"
              zIndex={1900}
              backGroundColor="#6f6f6f" //tuesdayGray
              blur
              titleTextAttribute="title-00"
              warningTitle={''}
              warningTextContainer={
                <>
                  <div
                    className="settlementDetailsParagraphContainer"
                    id="popUp-settlement0"
                  >
                    <div
                      className="settlementCalcuationsTable"
                      id="popUp-settlement1"
                    >
                      <div
                        className="settlementCalculationsRow"
                        id="popUp-settlement2"
                      >
                        <div
                          className="settlementCalculationsLeftPart"
                          id="popUp-settlement3"
                          data-textattribute="title-250"
                        >
                          {getText('title-250', user.settings)}:
                        </div>
                        <div
                          className="settlementCalculationsRightPart robotoNumbers"
                          id="popUp-settlement4"
                        >
                          {stringAndRoundBigNumbers(
                            resultsViewParty === 'opposing' &&
                              resultsFromBackend!.reversed
                              ? resultsFromBackend!.reversed.value_of_claim
                              : resultsFromBackend!.value_of_claim,
                            user.settings,
                            false,
                          )}
                        </div>
                      </div>
                      <div
                        className="settlementCalculationsRow"
                        id="popUp-settlement5"
                      >
                        <div
                          className="settlementCalculationsLeftPart"
                          id="popUp-settlement6"
                          data-textattribute="label-91"
                        >
                          {getText('label-91', user.settings)}:
                        </div>
                        <div
                          className="settlementCalculationsRightPart robotoNumbers"
                          id="popUp-settlement7"
                        >
                          {stringAndRoundBigNumbers(
                            totalIncurredLegalCosts,
                            user.settings,
                            false,
                          )}
                        </div>
                      </div>
                      {resultsSnapshot.hasInsurance && (
                        <div
                          className="settlementCalculationsRow"
                          id="popUp-settlement8"
                        >
                          <div
                            className="settlementCalculationsLeftPart"
                            id="popUp-settlement9"
                            data-textattribute="title-247"
                          >
                            {getText('title-247', user.settings)}:
                          </div>
                          <div
                            className="settlementCalculationsRightPart robotoNumbers"
                            id="popUp-settlement10"
                          >
                            -
                            {stringAndRoundBigNumbers(
                              resultsViewParty === 'opposing' &&
                                resultsFromBackend!.reversed
                                ? resultsFromBackend!.reversed.value_of_claim +
                                    totalIncurredLegalCosts -
                                    settlementAmount
                                : resultsFromBackend!.value_of_claim +
                                    totalIncurredLegalCosts -
                                    settlementAmount,
                              user.settings,
                              false,
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        className="settlementCalculationsLine"
                        id="popUp-settlement11"
                      ></div>
                      <div
                        className="settlementCalculationsRow"
                        id="popUp-settlement12"
                      >
                        <div
                          className="settlementCalculationsLeftPart bold"
                          id="popUp-settlement13"
                          data-textattribute="title-248"
                        >
                          {getText('title-248', user.settings)}:
                        </div>
                        <div
                          className="settlementCalculationsRightPart bold robotoNumbersBold"
                          id="popUp-settlement14"
                        >
                          {stringAndRoundBigNumbers(
                            settlementAmount,
                            user.settings,
                            false,
                          )}{' '}
                          {resultsSnapshot.currency}
                        </div>
                      </div>
                    </div>
                    <div
                      className="settlementDetailsContainer"
                      id="popUp-settlement15"
                    >
                      <span
                        className="settlementDetailsTitle"
                        data-textattribute="title-244"
                      >
                        {getText('title-244', user.settings)}
                      </span>

                      <p
                        className="settlementDetailsParagraph"
                        data-textattribute={
                          resultsSnapshot.hasInsurance
                            ? 'description-200'
                            : 'description-201'
                        }
                      >
                        {resultsSnapshot.hasInsurance
                          ? getText('description-200', user.settings)
                          : getText('description-201', user.settings)}
                      </p>
                    </div>
                  </div>
                </>
              }
              cancelFunction={() => setShowSettlementDetails(false)}
              customWidth={600}
            />
          ) : null}

          <div className="empty tool-component"></div>
        </div>
      )}
    </>
  )
}
