import { useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import {
  ExecutiveSummaryVisibleElements,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import useWindowSize from '../../../../../../../../customHooks/useWindowSize'
import { useEffect, useState } from 'react'
import { userState } from '../../../../../../../../states/UserState'
import { krogerusColorsState } from '../../../../../../../../states/KrogerusColorsState'
import { getText } from '../../../../../../../../services/textFunctions'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import IncludeOptionComponent from '../../IncludeOptionComponent'
import { includedIncurredCosts } from '../../../../../../../../services/resultsFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  reportWhitelabel?: ReportWhitelabel
  zopaExists: boolean
  visibleElements?: ExecutiveSummaryVisibleElements
  oneOutcomeForOpposingParty: boolean
  resultsViewParty: 'client' | 'opposing'
  setShowIncludeOption?: (
    showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined,
  ) => void
  showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined
  handleIncludeExcludeElement: (
    includeElementName: ExecutiveSummaryVisibleElementsEnum,
  ) => void
}

export default function ZopaGraph(props: Props) {
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  const windowSize = useWindowSize()

  const [widthClient, setWidthClient] = useState(0)
  const [widthOpposingParty, setWidthOpposingParty] = useState(0)
  const [totalDistance, setTotalDistance] = useState(600)
  const [sortedLimits, setSortedLimits] = useState<number[]>([0, 0, 0, 0])

  const valueOfCase1 =
    props.resultsFromBackend.value_of_claim +
    includedIncurredCosts(
      'client',
      'both',
      resultsSettings.settings.includeIncurredCosts,
      scenarioSnapshot.currentSnapshot,
    )
  const best1 =
    props.resultsFromBackend.minmax.max.financial_outcome +
    includedIncurredCosts(
      'client',
      'both',
      resultsSettings.settings.includeIncurredCosts,
      scenarioSnapshot.currentSnapshot,
    )
  const valueOfCase2 =
    -props.resultsFromBackend.reversed!.value_of_claim -
    includedIncurredCosts(
      'opposing',
      'both',
      resultsSettings.settings.includeIncurredCosts,
      scenarioSnapshot.currentSnapshot,
    )
  const best2 =
    -props.resultsFromBackend.reversed!.minmax.max.financial_outcome -
    includedIncurredCosts(
      'opposing',
      'both',
      resultsSettings.settings.includeIncurredCosts,
      scenarioSnapshot.currentSnapshot,
    )

  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  const fade =
    props.visibleElements &&
    !props.visibleElements[ExecutiveSummaryVisibleElementsEnum.newZopa]

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  useEffect(() => {
    const leftLimit = Math.min(valueOfCase1, best2)
    const rightLimit = Math.max(best1, valueOfCase2)

    const tempTotalDistance = Math.abs(rightLimit - leftLimit)
    let tempSortedLimits = [valueOfCase1, best1, valueOfCase2, best2]
    tempSortedLimits.sort((a, b) => a - b)

    setSortedLimits(tempSortedLimits)
    const settlement1X =
      (Math.abs(valueOfCase1 - best1) / tempTotalDistance) * 600

    const settlement2X =
      (Math.abs(valueOfCase2 - best2) / tempTotalDistance) * 600

    setWidthClient(settlement1X)
    setWidthOpposingParty(settlement2X)
    setTotalDistance(tempTotalDistance)

    // eslint-disable-next-line
  }, [props.resultsFromBackend, resultsSettings, props.resultsViewParty])

  return (
    <div
      className={`newZopaContainer ${props.visibleElements ? 'forReport' : ''}`}
      onMouseEnter={
        props.visibleElements
          ? () =>
              props.setShowIncludeOption!(
                ExecutiveSummaryVisibleElementsEnum.newZopa,
              )
          : undefined
      }
      onMouseLeave={
        props.visibleElements
          ? () => props.setShowIncludeOption!(undefined)
          : undefined
      }
    >
      {props.visibleElements &&
      props.showIncludeOption ===
        ExecutiveSummaryVisibleElementsEnum.newZopa ? (
        <IncludeOptionComponent
          includeElement={
            props.visibleElements[ExecutiveSummaryVisibleElementsEnum.newZopa]
          }
          includeElementName={ExecutiveSummaryVisibleElementsEnum.newZopa}
          handleIncludeExcludeElement={props.handleIncludeExcludeElement}
        />
      ) : null}
      <div
        className={`newZopaTitle ${fade ? 'fade' : ''}`}
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.bold,
          'noRoboto',
        )}
        data-textattribute="title-272"
      >
        {getText('title-272', user.settings)}
      </div>
      <div className="newZopaDescription">
        <p
          className={`newZopaDescriptionText ${fade ? 'fade' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
            true,
          )}
          data-textattribute="description-263"
        >
          {getText('description-263', user.settings)}
        </p>
        <p
          className={`newZopaDescriptionText ${fade ? 'fade' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
            true,
          )}
          data-textattribute="description-263b"
        >
          {getText('description-263b', user.settings)}
        </p>
      </div>
      <div className="zopaComponent" id="zopaComponent">
        <div
          className="zopaGraphOuterContainer"
          id="newZopaGraphOuterContainer"
        >
          <div
            className="zopaInnerContainer"
            style={best2 > valueOfCase2 ? { marginLeft: -60 } : undefined}
          >
            <div
              className="zopaGraphAppContainer"
              id="newZopaGraphAppContainer"
              style={
                !props.visibleElements &&
                windowSize.width < 1500 &&
                windowSize.width > 840
                  ? {
                      transform: `scale(${
                        windowSize.width > 1300
                          ? windowSize.width / 1500
                          : windowSize.width / 1600
                      })`,

                      // marginLeft: +160 - 160 * (1500 / windowSize.width),
                    }
                  : !props.visibleElements && windowSize.width <= 840
                  ? { transform: `scale(${840 / 1600})` }
                  : props.visibleElements
                  ? { transform: `scale(${0.9})`, marginLeft: 0 }
                  : undefined
              }
            >
              <div className="zopaGraphContainer">
                <div className="zopaGraph">
                  <div
                    className={`zopaBar ${krogerusColors ? 'krogerus' : ''}`}
                    id="zopaGraphOpposingParty"
                    style={{
                      marginLeft:
                        best2 < valueOfCase2
                          ? (Math.abs(best2 - sortedLimits[0]) /
                              totalDistance) *
                            600
                          : (Math.abs(valueOfCase2 - sortedLimits[0]) /
                              totalDistance) *
                            600,
                      width: widthOpposingParty,
                    }}
                  >
                    <div
                      className="zopaTick top"
                      style={{
                        left:
                          best2 < valueOfCase2
                            ? (Math.abs(best2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              92
                            : (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              92,
                      }}
                    ></div>
                    <div
                      className="zopaTickTop top"
                      style={{
                        left:
                          best2 < valueOfCase2
                            ? (Math.abs(best2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              92 -
                              7.5
                            : (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              92 -
                              7.5,
                      }}
                    ></div>

                    <p
                      className="zopaTickText top left"
                      id="zopaTickTextTopLeft"
                      style={{
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'noRoboto',
                          false,
                          true,
                        ),
                        left:
                          best2 < valueOfCase2
                            ? (Math.abs(best2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91 -
                              240
                            : (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91 -
                              240,
                        textAlign: best2 > valueOfCase2 ? 'left' : 'right',
                      }}
                      text-dataattribute={
                        best2 < valueOfCase2
                          ? 'description-264'
                          : 'description-265'
                      }
                    >
                      {best2 < valueOfCase2
                        ? getText(
                            'description-264',
                            user.settings,
                            partiesFormat,
                          )
                        : getText(
                            'description-265',
                            user.settings,
                            partiesFormat,
                          )}
                    </p>

                    {best2 < valueOfCase2 && (
                      <p
                        className="zopaTickNumber robotoNumbers"
                        id="zopaTickNumberTopLeft"
                        style={{
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'roboto',
                            false,
                            true,
                          ),
                          left:
                            best2 < valueOfCase2
                              ? (Math.abs(best2 - sortedLimits[0]) /
                                  totalDistance) *
                                  600 -
                                200 +
                                85
                              : (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                  totalDistance) *
                                  600 -
                                200 +
                                85,
                        }}
                      >
                        {stringAndRoundBigNumbers(
                          -best2,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                        )}
                      </p>
                    )}

                    {props.oneOutcomeForOpposingParty === false && (
                      <>
                        <div
                          className="zopaTick top"
                          style={{
                            left:
                              best2 < valueOfCase2
                                ? (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                    totalDistance) *
                                    600 +
                                  91
                                : (Math.abs(best2 - sortedLimits[0]) /
                                    totalDistance) *
                                    600 +
                                  91,
                          }}
                        ></div>
                        <div
                          className="zopaTickTop top"
                          style={{
                            left:
                              best2 < valueOfCase2
                                ? (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                    totalDistance) *
                                    600 +
                                  91
                                : (Math.abs(best2 - sortedLimits[0]) /
                                    totalDistance) *
                                    600 +
                                  91,
                          }}
                        ></div>

                        <p
                          className="zopaTickText top right"
                          id="zopaTickTextTopRight"
                          style={{
                            ...getStyleFromWhitelabel(
                              props.reportWhitelabel,
                              ReportWhitelabelKeys.sectionDescription,
                              FontLabels.regular,
                              'noRoboto',
                              false,
                              true,
                            ),
                            left:
                              best2 < valueOfCase2
                                ? (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                    totalDistance) *
                                    600 +
                                  102
                                : (Math.abs(best2 - sortedLimits[0]) /
                                    totalDistance) *
                                    600 -
                                  10,
                          }}
                          text-dataattribute={
                            best2 < valueOfCase2
                              ? 'description-265'
                              : 'description-264'
                          }
                        >
                          {best2 < valueOfCase2
                            ? getText(
                                'description-265',
                                user.settings,
                                partiesFormat,
                              )
                            : getText(
                                'description-264',
                                user.settings,
                                partiesFormat,
                              )}
                        </p>

                        {(!props.zopaExists || best2 > valueOfCase2) && (
                          <p
                            className="zopaTickNumber right robotoNumbers"
                            id="zopaTickNumberTopRight"
                            style={{
                              ...getStyleFromWhitelabel(
                                props.reportWhitelabel,
                                ReportWhitelabelKeys.sectionDescription,
                                FontLabels.regular,
                                'roboto',
                                false,
                                true,
                              ),
                              left:
                                best2 < valueOfCase2
                                  ? (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                      totalDistance) *
                                      600 +
                                    99
                                  : (Math.abs(best2 - sortedLimits[0]) /
                                      totalDistance) *
                                      600 +
                                    99,
                            }}
                          >
                            {best2 < valueOfCase2
                              ? `${stringAndRoundBigNumbers(
                                  -valueOfCase2,
                                  user.settings,
                                  resultsSettings.settings.roundedResults,
                                )}${
                                  props.resultsFromBackend &&
                                  props.resultsFromBackend.model_used ===
                                    'statistical'
                                    ? '*'
                                    : ''
                                }`
                              : stringAndRoundBigNumbers(
                                  -best2,
                                  user.settings,
                                  resultsSettings.settings.roundedResults,
                                )}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className={`zopaBar ${krogerusColors ? 'krogerus' : ''}`}
                    id="zopaGraphZOPA"
                    style={{
                      width: props.zopaExists
                        ? Math.max(
                            Math.abs(
                              (Math.abs(valueOfCase2 - valueOfCase1) /
                                totalDistance) *
                                600,
                            ),
                            1,
                          )
                        : 0,

                      marginLeft:
                        (Math.abs(valueOfCase1 - sortedLimits[0]) /
                          totalDistance) *
                        600,
                    }}
                  >
                    {props.zopaExists ? (
                      <>
                        <p
                          className="zopaTickNumber zopa robotoNumbersBold"
                          id="zopaTickNumberZopaLeft"
                          style={{
                            ...getStyleFromWhitelabel(
                              props.reportWhitelabel,
                              ReportWhitelabelKeys.sectionDescription,
                              FontLabels.regular,
                              'roboto',
                              false,
                              props.visibleElements &&
                                props.visibleElements[
                                  ExecutiveSummaryVisibleElementsEnum.zopa
                                ] === false
                                ? true
                                : false,
                            ),
                            left:
                              (Math.abs(valueOfCase1 - sortedLimits[0]) /
                                totalDistance) *
                                600 -
                              200 +
                              85,
                          }}
                        >
                          {stringAndRoundBigNumbers(
                            props.resultsViewParty === 'opposing'
                              ? -valueOfCase1
                              : valueOfCase1,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                          )}
                          {props.resultsFromBackend &&
                          props.resultsFromBackend.model_used === 'statistical'
                            ? '*'
                            : ''}
                        </p>

                        <p
                          className="zopaTickNumber right zopa robotoNumbersBold"
                          id="zopaTickNumberZopaRight"
                          style={{
                            ...getStyleFromWhitelabel(
                              props.reportWhitelabel,
                              ReportWhitelabelKeys.sectionDescription,
                              FontLabels.regular,
                              'roboto',
                              false,
                              props.visibleElements &&
                                props.visibleElements[
                                  ExecutiveSummaryVisibleElementsEnum.zopa
                                ] === false
                                ? true
                                : false,
                            ),
                            left:
                              (Math.abs(valueOfCase2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              99,
                          }}
                        >
                          {stringAndRoundBigNumbers(
                            props.resultsViewParty === 'opposing'
                              ? -valueOfCase2
                              : valueOfCase2,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                          )}
                          {props.resultsFromBackend &&
                          props.resultsFromBackend.model_used === 'statistical'
                            ? '*'
                            : ''}
                        </p>
                      </>
                    ) : (
                      <p
                        className="nozopaText"
                        id="noZopaText"
                        data-textattribute="description-219"
                        style={{
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'noRoboto',
                            false,
                            true,
                          ),
                        }}
                      >
                        {getText('description-219', user.settings)}
                      </p>
                    )}
                  </div>
                  <div
                    className={`zopaBar ${krogerusColors ? 'krogerus' : ''}`}
                    id="zopaGraphClient"
                    style={{
                      width: widthClient,
                      marginLeft:
                        valueOfCase1 < best1
                          ? (Math.abs(valueOfCase1 - sortedLimits[0]) /
                              totalDistance) *
                            600
                          : (Math.abs(best1 - sortedLimits[0]) /
                              totalDistance) *
                            600,
                    }}
                  >
                    <div
                      className="zopaTick bottom"
                      style={{
                        left:
                          valueOfCase1 < best1
                            ? (Math.abs(valueOfCase1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              92
                            : (Math.abs(best1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              92,
                      }}
                    ></div>
                    <div
                      className="zopaTickTop bottom"
                      style={{
                        left:
                          valueOfCase1 < best1
                            ? (Math.abs(valueOfCase1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              92 -
                              7.5
                            : (Math.abs(best1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              92 -
                              7.5,
                      }}
                    ></div>

                    <p
                      className="zopaTickText bottom left"
                      id="zopaTickTextBottomLeft"
                      style={{
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'noRoboto',
                          false,
                          true,
                        ),
                        left:
                          valueOfCase1 < best1
                            ? (Math.abs(valueOfCase1 - sortedLimits[0]) /
                                totalDistance) *
                                600 -
                              155
                            : (Math.abs(best1 - sortedLimits[0]) /
                                totalDistance) *
                                600 -
                              75,
                        top: valueOfCase1 < best1 ? 110 : 135,
                      }}
                      text-dataattribute={
                        valueOfCase1 < best1
                          ? 'description-266'
                          : 'description-267'
                      }
                    >
                      {valueOfCase1 < best1
                        ? getText(
                            'description-266',
                            user.settings,
                            partiesFormat,
                          )
                        : getText(
                            'description-267',
                            user.settings,
                            partiesFormat,
                          )}
                    </p>

                    {(!props.zopaExists || valueOfCase1 > best1) && (
                      <p
                        className="zopaTickNumber client robotoNumbers"
                        id="zopaTickNumberBottomLeft"
                        style={{
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'roboto',
                            false,
                            true,
                          ),
                          left:
                            valueOfCase1 < best1
                              ? (Math.abs(valueOfCase1 - sortedLimits[0]) /
                                  totalDistance) *
                                  600 -
                                200 +
                                85
                              : (Math.abs(best1 - sortedLimits[0]) /
                                  totalDistance) *
                                  600 -
                                200 +
                                85,
                        }}
                      >
                        {valueOfCase1 < best1
                          ? `${stringAndRoundBigNumbers(
                              valueOfCase1,
                              user.settings,
                              resultsSettings.settings.roundedResults,
                            )}${
                              props.resultsFromBackend &&
                              props.resultsFromBackend.model_used ===
                                'statistical'
                                ? '*'
                                : ''
                            }`
                          : stringAndRoundBigNumbers(
                              best1,
                              user.settings,
                              resultsSettings.settings.roundedResults,
                            )}
                      </p>
                    )}
                    <div
                      className="zopaTick bottom"
                      style={{
                        left:
                          valueOfCase1 < best1
                            ? (Math.abs(best1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91
                            : (Math.abs(valueOfCase1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91,
                      }}
                    ></div>
                    <div
                      className="zopaTickTop bottom"
                      style={{
                        left:
                          valueOfCase1 < best1
                            ? (Math.abs(best1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91
                            : (Math.abs(valueOfCase1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91,
                      }}
                    ></div>

                    <p
                      className="zopaTickText bottom right"
                      id="zopaTickTextBottomRight"
                      style={{
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'noRoboto',
                          false,
                          true,
                        ),
                        left:
                          valueOfCase1 < best1
                            ? (Math.abs(best1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              105
                            : (Math.abs(valueOfCase1 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              105,
                        width: 170,
                      }}
                      text-dataattribute={
                        valueOfCase1 < best1
                          ? 'description-267'
                          : 'description-266'
                      }
                    >
                      {valueOfCase1 < best1
                        ? getText(
                            'description-267',
                            user.settings,
                            partiesFormat,
                          )
                        : getText(
                            'description-266',
                            user.settings,
                            partiesFormat,
                          )}
                    </p>

                    {valueOfCase1 < best1 && (
                      <p
                        className="zopaTickNumber right client robotoNumbers"
                        id="zopaTickNumberBottomRight"
                        style={{
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'roboto',
                            false,
                            true,
                          ),
                          left:
                            (Math.abs(best1 - sortedLimits[0]) /
                              totalDistance) *
                              600 +
                            99,
                        }}
                      >
                        {stringAndRoundBigNumbers(
                          best1,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                        )}{' '}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {fade && <div className="hiddenSectionLayerContainerZOPA"></div>}
        </div>
      </div>
    </div>
  )
}
