import React, { useEffect, useState } from 'react'

import { ResultsObject } from '../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../models/scenarioSnapshot'
import { getText } from '../../../../../../services/textFunctions'
import { ActionMessage } from '../../../../../../models/enums'
// import VideoInfoButton from '../../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { fullscreenActionMessageState } from '../../../../../../states/FullScreenActionMessageState'
import DownloadImage from '../../../../../../Components/downloadImages/DownloadImage'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { stringAndRoundBigNumbers } from '../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { resultsSettingsState } from '../../../../../../states/ResultsSettingsState'
import ExpectedValueBreakdownForReport from './ExpectedValueBreakdownForReport'
import AwaLegendComponent from '../../../../../../Components/common/AwaLegendComponent'
import { includedIncurredCosts } from '../../../../../../services/resultsFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  resultsSnapshot: ScenarioSnapshot
  setShowAWADetails: (showAWADetails: boolean) => void
  resultsViewParty: 'client' | 'opposing'
  loadingResultsSettings?: boolean
}

export default function ExpectedValueBreakdownForResults(props: Props) {
  const user = useRecoilValue(userState)
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [saveGraphImageName, setSaveGraphImageName] = useState('')
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const partiesFormat = [
    props.resultsSnapshot.partyFormatOwn,
    props.resultsSnapshot.partyFormatOther,
  ]

  useEffect(() => {
    // data-textattribute="title-276"
    let tempSaveGraphImageName = `${scenarioIdentity.caseName} - ${
      scenarioIdentity.scenarioName
    } - ${getText('title-276', user.settings)} - ${
      props.resultsViewParty === 'client'
        ? getText('title-260', user.settings, partiesFormat)
        : getText('title-261', user.settings, partiesFormat)
    }`
    setSaveGraphImageName(tempSaveGraphImageName)

    // let totalValue = 0
    // for (let key of Object.keys(props.resultsFromBackend.values_breakdown)) {
    //   totalValue += props.resultsFromBackend.values_breakdown[
    //     key as keyof typeof props.resultsFromBackend.values_breakdown
    //   ] as number
    // }

    // console.log('totalValue')
    // console.log(totalValue)

    // eslint-disable-next-line
  }, [user, partiesFormat])

  return (
    <div
      className="tool-component results-tool-component"
      id={`graphComp-expectedValuesBreakdown`}
      style={
        fullscreenActionMessage === ActionMessage.downloadingImage
          ? { marginTop: 0, minHeight: 500 }
          : undefined
      }
    >
      <div
        className="downloadImageComponentContainer"
        style={
          fullscreenActionMessage === ActionMessage.downloadingImage
            ? { visibility: 'hidden' }
            : undefined
        }
      >
        <DownloadImage
          id={'expectedValueBreakdown'}
          tooltipTextAttribute={'tooltip-68'}
          tooltipText={getText('tooltip-68', user.settings)}
          imageElementId={`graphComp-expectedValuesBreakdown`}
          saveName={saveGraphImageName}
          downloadImageType="results"
        />
      </div>
      <div
        className="tool-component-title"
        id={`graphTitle-expectedValueBreakdown`}
        data-textattribute="title-276"
      >
        {getText('title-276', user.settings)}
      </div>
      <p
        className="graphSubtitle"
        id={`graphSubtitle-expectedValueBreakdown`}
        data-textattribute="description-279"
      >
        {getText('description-279', user.settings)}{' '}
        <span className="robotoNumbers">
          {stringAndRoundBigNumbers(
            props.resultsFromBackend.value_of_claim +
              includedIncurredCosts(
                'client',
                'both',
                resultsSettings.settings.includeIncurredCosts,
                props.resultsSnapshot,
              ),
            user.settings,
            resultsSettings.settings.roundedResults,
          )}
          {props.resultsFromBackend.model_used === 'statistical' ? '*' : ''}{' '}
          {props.resultsSnapshot.currency}.
        </span>
      </p>
      <ExpectedValueBreakdownForReport
        resultsFromBackend={props.resultsFromBackend}
        resultsViewParty={props.resultsViewParty}
        resultsSnapshot={props.resultsSnapshot}
        resultsSettings={resultsSettings}
      />
      <AwaLegendComponent
        resultsFromBackend={props.resultsFromBackend}
        user={user}
        currentSnapshot={props.resultsSnapshot}
        type="expectedValueBreakdown"
        setShowAWADetails={props.setShowAWADetails}
        downloadingImage={
          fullscreenActionMessage === ActionMessage.downloadingImage
        }
      />
    </div>
  )
}
