import React from 'react'

import { PageType } from '../UserPopUp'
import tickedImg from '../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../resources/images/018-untickedGrayBlue.svg'
import CircularProgress from '@mui/material/CircularProgress'
import InputText from '../../../Components/Inputs/InputText/InputText'
import microsoftLogo from '../../../resources/images/303-microsoftLogo.png'
import { getText } from '../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import { featuresState } from '../../../states/FeaturesState'

type Props = {
  validationMessage: string | undefined
  email: string
  password: string
  setEmail: (value: string) => void
  setPassword: (value: string) => void
  setPage: (value: PageType) => void
  loading: boolean
  microsoftLoading: boolean
  errors: string[]
  handleMicrosoftSsoLogin: () => void
  loadingFeatures: boolean
  admin?: boolean
}

export default function Login(props: Props) {
  const user = useRecoilValue(userState)
  const features = useRecoilValue(featuresState)

  function handleClickOnShowPasswordTick() {
    if (
      document.getElementById('showPasswordUnticked')!.className ===
      'visibleTick'
    ) {
      document.getElementById('showPasswordUnticked')!.className = 'hiddenTick'
      document.getElementById('showPasswordTicked')!.className = 'visibleTick'
      ;(document.getElementById('logInPassword')! as HTMLInputElement).type =
        'text'
    } else if (
      document.getElementById('showPasswordTicked')!.className === 'visibleTick'
    ) {
      document.getElementById('showPasswordTicked')!.className = 'hiddenTick'
      document.getElementById('showPasswordUnticked')!.className = 'visibleTick'
      ;(document.getElementById('logInPassword')! as HTMLInputElement).type =
        'password'
    }
  }

  const failedMessage = () => {
    const mes1 = props.validationMessage!.split('\\n')[0]
    const mes2 = props.validationMessage!.split('\\n')[1]
    const mes3 = props.validationMessage!.split('\\n')[2]

    return (
      <>
        {mes1}
        <br />
        {mes2}
        <p className="additionalInfo">{mes3}</p>
      </>
    )
  }

  return (
    !props.loadingFeatures && (
      <>
        <div
          className={`welcomeMessageContainer ${
            features.use_microsoft_sso && !props.admin
              ? 'ssoWelcomeMessageContainer'
              : ''
          }`}
        >
          <h2
            className="welcome"
            id="loginTitle"
            data-textattribute="title-110"
          >
            {getText('title-110', user.settings)}
          </h2>
          {(features.use_microsoft_sso === false || props.admin) && (
            <div
              className={`instructions ${
                props.validationMessage ? 'errorClass' : ''
              }`}
              id="loginInstructions"
              data-textattribute={
                props.validationMessage ? 'error-00' : 'description-84'
              }
            >
              {props.validationMessage
                ? failedMessage()
                : getText('description-84', user.settings)}
            </div>
          )}
        </div>
        {user.microsoftAuthorizedButNotAUser && (
          <div className="ssoErroLoginContainer">
            <p
              className="ssoErroLoginText"
              data-textattribute="error-97a, error-97b"
            >
              {getText('error-97a', user.settings)} (
              {user.microsoftAuthorizedButNotAUser}){' '}
              {getText('error-97b', user.settings)}
            </p>
          </div>
        )}
        {(features.use_microsoft_sso === false || props.admin) && (
          <>
            <div className="inputsContainer">
              <div className="inputEmail">
                <InputText
                  label={getText('label-55', user.settings)}
                  labelTextAttribute="label-55"
                  id="logInEmail"
                  width={470}
                  onChange={(e) => props.setEmail(e.target.value)}
                  value={props.email}
                  autoComplete
                  alwaysShrink
                  name="logInEmail"
                  helperText={getText('helperText-4', user.settings)}
                  helperTextAttribute="helperText-4"
                  error={
                    props.errors.includes('logInEmail') ||
                    props.errors.includes('logInEmail:validation')
                  }
                  errorMessage={
                    props.errors.includes('logInEmail:validation')
                      ? getText('error-38', user.settings)
                      : getText('error-39', user.settings)
                  }
                  errorTextAttribute={
                    props.errors.includes('logInEmail:validation')
                      ? 'error-38'
                      : 'error-39'
                  }
                  tabIndex={1}
                  maxLength={50}
                  noDelay
                />
              </div>

              <div className="inputPassword">
                <InputText
                  label={getText('label-56', user.settings)}
                  labelTextAttribute="label-56"
                  id="logInPassword"
                  type="password"
                  width={470}
                  onChange={(e) => props.setPassword(e.target.value)}
                  value={props.password}
                  name="logInPassword"
                  error={props.errors.includes('logInPassword')}
                  errorMessage={getText('error-84', user.settings)}
                  errorTextAttribute={'error-84'}
                  hideHelperText
                  alwaysShrink
                  tabIndex={2}
                  maxLength={64}
                  noDelay
                />
              </div>
              <div className="showPassword">
                <img
                  className="visibleTick"
                  id="showPasswordUnticked"
                  src={unTickedImg}
                  alt="untickedImg"
                  onClick={handleClickOnShowPasswordTick}
                />
                <img
                  className="hiddenTick"
                  id="showPasswordTicked"
                  src={tickedImg}
                  alt="tickedImg"
                  onClick={handleClickOnShowPasswordTick}
                />
                <p
                  className="tickText"
                  onClick={handleClickOnShowPasswordTick}
                  id="logintickText"
                  data-textattribute="submenu-17"
                >
                  {getText('submenu-17', user.settings)}
                </p>
              </div>
              <div className="forgotContainer">
                <p
                  className="forgot"
                  onClick={() => props.setPage('ForgotPassword')}
                  id="login-forgotPassword"
                  data-textattribute="title-111"
                >
                  {getText('title-111', user.settings)}
                </p>
              </div>
            </div>
            <div
              className={`submitButtonContainer ${props.admin ? 'admin' : ''}`}
            >
              <button
                type="submit"
                className={`submitButton`}
                disabled={props.loading}
                id="submitLoginButton"
                data-textattribute="button-53"
              >
                {props.loading ? (
                  <CircularProgress size={22} />
                ) : (
                  getText('button-53', user.settings)
                )}
              </button>
            </div>
          </>
        )}
        <div className="submitButtonContainer">
          {features.use_microsoft_sso && (
            <div className="ssoButtonContainer">
              <p
                className="ssoText"
                data-textattribute="description-280, description-281"
              >
                {user.microsoftAuthorizedButNotAUser
                  ? getText('description-280', user.settings)
                  : getText('description-281', user.settings)}
              </p>

              <button
                type="button"
                className={`ssoButton`}
                disabled={props.loading}
                id="ssoLoginButton"
                data-textattribute="button-..."
                onClick={props.handleMicrosoftSsoLogin}
              >
                {props.microsoftLoading ? (
                  <CircularProgress size={22} />
                ) : (
                  <>
                    <img
                      className="ssoButtonImg"
                      src={microsoftLogo}
                      alt="Microsoft"
                    />
                    <p
                      className="ssoButtonText"
                      data-textattribute="button-165"
                    >
                      {getText('button-165', user.settings)}
                    </p>
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      </>
    )
  )
}
