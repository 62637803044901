import { atom } from 'recoil'
import { Currency } from '../models/enums'
import { ReportStateType } from '../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'

export const reportState = atom<ReportStateType>({
  key: 'reportState',
  default: {
    reportData: {
      date: 'currentDate',
      sections: [
        {
          title: '',
          customTitle: '',
          contents: {},
          show: true,
          sectionOrder: 0,
          sectionId: 'section-0',
        },
      ],
      version: 1,
    },
    reportScenarioSnapshot: ScenarioSnapshot.EmptySnapshot(
      'Client',
      'Opposing_Party',
      Currency.SEK,
    ),
  },
})
