import { useRecoilState, useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../../models/generalTypes'
import {
  ExecutiveSummaryVisibleElements,
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../models/reportModels/reportGeneralTypes'
import { reverseCase } from '../../../../../../../services/reverseCaseFunctions'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../../../../states/UserState'
import {
  calculateZOPA,
  deepCloneObject,
} from '../../../../../../../services/commonFunctions'
import { getText } from '../../../../../../../services/textFunctions'
import { useEffect, useState } from 'react'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../../../services/reportFunctions'
import ClaimsAndLegalCosts from './executiveSummary3Components/ClaimsAndLegalCosts'
import ExpectedValue from './executiveSummary3Components/ExpectedValue'
import BestWorstScenario from './executiveSummary3Components/BestWorstScenarioExecutive'
import { reportState } from '../../../../../../../states/ReportState'
import AwaLegendComponent from '../../../../../../../Components/common/AwaLegendComponent'
import NewZopa from './executiveSummary3Components/NewZopa'
import Button from '../../../../../../../Components/Buttons/Button/Button'
import { resultsSettingsState } from '../../../../../../../states/ResultsSettingsState'

type Props = {
  resultsFromBackend: ResultsObject
  visibleElements?: ExecutiveSummaryVisibleElements
  sectionIndex?: number
  setShowAWADetails?: (showAWADetails: boolean) => void
  reportWhitelabel?: ReportWhitelabel
  legalCostsDescriptions?: ReportLegalCostsType
  resultsViewParty: 'client' | 'opposing'
  downloadingImage?: boolean
}

export default function ExecutiveSummaryNew3Container(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const reversedSnapshot = reverseCase(scenarioSnapshot.currentSnapshot)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const backendResults =
    props.resultsViewParty === 'client'
      ? props.resultsFromBackend
      : props.resultsFromBackend.reversed!

  const resultsSnapshot =
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot
      : reversedSnapshot

  const [showIncludeOption, setShowIncludeOption] = useState<
    ExecutiveSummaryVisibleElementsEnum | undefined
  >(undefined)
  const [report, setReport] = useRecoilState(reportState)
  const [zopaExists, setZopaExists] = useState(true)

  const oneOutcomeForOpposingParty = props.resultsFromBackend.reversed
    ? props.resultsFromBackend.reversed.minmax.max.financial_outcome ===
      props.resultsFromBackend.reversed.minmax.min.financial_outcome
    : false

  useEffect(() => {
    if (props.resultsFromBackend.reversed) {
      const calculationZOPA = calculateZOPA(
        props.resultsFromBackend,
        'new',
        resultsSettings.settings.includeIncurredCosts,
        resultsSnapshot,
      )
      if (calculationZOPA === 'noZOPA') {
        setZopaExists(false)
      } else {
        setZopaExists(true)
      }
    }
    // eslint-disable-next-line
  }, [resultsSettings])

  function handleIncludeExcludeElement(
    includeElementName: ExecutiveSummaryVisibleElementsEnum,
  ) {
    let tempReportData = deepCloneObject(report.reportData)

    tempReportData.sections[
      props.sectionIndex!
    ].contents.executiveSummary.visibleElements[includeElementName] =
      !tempReportData.sections[props.sectionIndex!].contents.executiveSummary
        .visibleElements[includeElementName]
    setReport({ ...report, reportData: tempReportData })
  }

  return (
    <div
      className={`executiveSummary3Container ${
        props.visibleElements ? 'forReport' : ''
      }`}
    >
      <ClaimsAndLegalCosts
        resultsViewParty={props.resultsViewParty}
        reportWhitelabel={props.reportWhitelabel}
        visibleElements={props.visibleElements}
        setShowIncludeOption={
          props.visibleElements ? setShowIncludeOption : undefined
        }
        showIncludeOption={showIncludeOption}
        handleIncludeExcludeElement={handleIncludeExcludeElement}
      />
      <ExpectedValue
        reportWhitelabel={props.reportWhitelabel}
        backendResults={backendResults}
        visibleElements={props.visibleElements}
        resultsViewParty={props.resultsViewParty}
        setShowIncludeOption={
          props.visibleElements ? setShowIncludeOption : undefined
        }
        showIncludeOption={showIncludeOption}
        handleIncludeExcludeElement={handleIncludeExcludeElement}
        resultsSnapshot={resultsSnapshot}
      />
      <BestWorstScenario
        reportWhitelabel={props.reportWhitelabel}
        visibleElements={props.visibleElements}
        backendResults={backendResults}
        resultsViewParty={props.resultsViewParty}
        setShowIncludeOption={
          props.visibleElements ? setShowIncludeOption : undefined
        }
        showIncludeOption={showIncludeOption}
        handleIncludeExcludeElement={handleIncludeExcludeElement}
      />
      {props.resultsFromBackend.reversed ? (
        <NewZopa
          resultsFromBackend={props.resultsFromBackend}
          zopaExists={zopaExists}
          visibleElements={props.visibleElements}
          reportWhitelabel={props.reportWhitelabel}
          oneOutcomeForOpposingParty={oneOutcomeForOpposingParty}
          resultsViewParty={props.resultsViewParty}
          setShowIncludeOption={
            props.visibleElements ? setShowIncludeOption : undefined
          }
          showIncludeOption={showIncludeOption}
          handleIncludeExcludeElement={handleIncludeExcludeElement}
        />
      ) : (
        !props.visibleElements && (
          <div className="calculateZopaContainer">
            <div
              className="newZopaTitle"
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
              )}
              data-textattribute="title-272"
            >
              {getText('title-272', user.settings)}
            </div>
            <p className="calculateZopaDescription">
              {getText('description-202', user.settings)}
            </p>
            <Button
              id={'calculateZopa'}
              buttonText={'CALCULATE ZOPA'}
              buttonTextAttribute={'button-0'}
              type={'submit'}
              className="calculateZopaButton"
              buttonType="outlined"
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </div>
        )
      )}
      <div
        className={`executiveSummaryLegendsContainer ${
          props.visibleElements ? 'forReport' : ''
        }`}
        data-openreplay-obscured
      >
        <p
          className={`executiveSummaryLegendText ${
            props.visibleElements &&
            !props.visibleElements[
              ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
            ]
              ? 'fade'
              : ''
          }`}
          id={`executiveSummaryLegendText${
            props.visibleElements ? '-forReport' : ''
          }1`}
          data-textattribute="description-257"
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
          )}
        >
          <sup
            style={{
              ...getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
              ),
              fontWeight: 600,
            }}
            className={`${
              props.visibleElements &&
              !props.visibleElements[
                ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
              ]
                ? 'fade'
                : ''
            }`}
          >
            1{' '}
          </sup>
          {getText('description-257', user.settings)}
        </p>
        <p
          className={`executiveSummaryLegendText ${
            props.visibleElements &&
            !props.visibleElements[
              ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
            ]
              ? 'fade'
              : ''
          }`}
          id={`executiveSummaryLegendText${
            props.visibleElements ? '-forReport' : ''
          }2`}
          data-textattribute="description-258"
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
          )}
        >
          <sup
            style={{
              ...getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
              ),
              fontWeight: 600,
            }}
            className={`${
              props.visibleElements &&
              !props.visibleElements[
                ExecutiveSummaryVisibleElementsEnum.claimsAndLegalCosts
              ]
                ? 'fade'
                : ''
            }`}
          >
            2{' '}
          </sup>
          {getText('description-258', user.settings)}
        </p>
        <p
          className={`executiveSummaryLegendText ${
            props.visibleElements && !props.visibleElements.newBestWorstScenario
              ? 'fade'
              : ''
          }`}
          id={`executiveSummaryLegendText${
            props.visibleElements ? '-forReport' : ''
          }3`}
          data-textattribute={`${
            props.resultsViewParty === 'client'
              ? 'description-259'
              : 'description-260'
          }`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
          )}
        >
          <sup
            style={{
              ...getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
              ),
              fontWeight: 600,
            }}
            className={`${
              props.visibleElements &&
              !props.visibleElements.newBestWorstScenario
                ? 'fade'
                : ''
            }`}
          >
            3{' '}
          </sup>
          {props.resultsViewParty === 'client'
            ? getText('description-259', user.settings, partiesFormat)
            : getText('description-260', user.settings, partiesFormat)}
        </p>

        <AwaLegendComponent
          resultsFromBackend={backendResults}
          reportWhitelabel={props.reportWhitelabel}
          user={user}
          currentSnapshot={resultsSnapshot}
          type="newExecutiveSummary"
          visibleElements={props.visibleElements}
          setShowAWADetails={props.setShowAWADetails}
          pdfReport={props.visibleElements !== undefined}
          style={{ marginLeft: '0px', fontSize: '10px', marginTop: '0px' }}
          downloadingImage={props.downloadingImage}
        />
      </div>
    </div>
  )
}
