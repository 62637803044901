import React from 'react'
import '../HoverComponent.css'

type Props = {
  hoverText: any
  id: string
  style?: React.CSSProperties
  textAttribute: string
}

export default function HoverComponent(props: Props) {
  return (
    <>
      <div
        className="infoHoverTextContainer"
        id={`infoHoverTextContainer-${props.id}`}
        style={props.style}
      >
        <div
          className="infoHoverText"
          id={`infoHoverText-${props.id}`}
          data-textattribute={props.textAttribute}
          data-openreplay-obscured
        >
          {props.hoverText}
        </div>
      </div>
    </>
  )
}
