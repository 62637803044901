import { useEffect, useState } from 'react'
import SelectionGraph from '../../../../7_results/resultsComponents/2_graphs/graphComponents/selectionGraph/SelectionGraph'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  TreeTableInfo,
  TreeTablesState,
} from '../../../../../../../models/generalTypes'
import { createTreeSelectionGraph } from '../../../../../../../services/treeFunctions/treeBasicFunctions'
import { treeTablesState } from '../../../../../../../states/TreeTablesState'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../services/commonFunctions'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'
import { freemiumState } from '../../../../../../../states/FreemiumState'
import {
  checkIfTreeHasInterest,
  treeHasOnlyAmount,
  treeHasOutOfCourt,
} from '../../../../../../../services/resultsFunctions'

type Props = {
  treeIndex: number
  currentTreeTableInfo: TreeTableInfo | undefined
}

export default function TreeSelectionGraphContainer(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  const [treeSelectionGraph, setTreeSelectionGraph] = useState<
    [number, number][] | undefined
  >(undefined)

  const [treeTableColumnsState, setTreeTableColumnsState] = useState<
    | 'onlyAmount'
    | 'onlyInterest'
    | 'onlyExternal'
    | 'externalInterest'
    | undefined
  >(undefined)

  function toggleSelectionGraph(selectionGraphOn: boolean) {
    const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
      (treeTableInfo) =>
        treeTableInfo.treeId ===
        scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
    )

    let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
    if (
      tempTreeTables.treeTablesInfo &&
      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
    ) {
      tempTreeTables.treeTablesInfo[treeTableInfoIndex].showSelectionGraph =
        selectionGraphOn
      setTreeTables(tempTreeTables)
    }
  }

  useEffect(() => {
    if (props.currentTreeTableInfo && props.currentTreeTableInfo.results) {
      const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
        (treeTableInfo) =>
          treeTableInfo.treeId ===
          scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
      )

      let tempTreeResultsGraph = createTreeSelectionGraph(
        props.currentTreeTableInfo.results!,
        treeTables.treeTablesInfo[treeTableInfoIndex].treeInterestViewOption,
      )
      setTreeSelectionGraph(tempTreeResultsGraph)
      if (treeHasOnlyAmount(props.currentTreeTableInfo?.results[0])) {
        setTreeTableColumnsState('onlyAmount')
      } else if (
        checkIfTreeHasInterest(
          props.currentTreeTableInfo?.results[0],
          scenarioSnapshot.currentSnapshot,
        ) &&
        !treeHasOutOfCourt(props.currentTreeTableInfo?.results[0])
      ) {
        setTreeTableColumnsState('onlyInterest')
      } else if (
        !checkIfTreeHasInterest(
          props.currentTreeTableInfo?.results[0],
          scenarioSnapshot.currentSnapshot,
        ) &&
        treeHasOutOfCourt(props.currentTreeTableInfo?.results[0])
      ) {
        setTreeTableColumnsState('onlyExternal')
      } else if (
        checkIfTreeHasInterest(
          props.currentTreeTableInfo?.results[0],
          scenarioSnapshot.currentSnapshot,
        ) &&
        treeHasOutOfCourt(props.currentTreeTableInfo?.results[0])
      ) {
        setTreeTableColumnsState('externalInterest')
      }
    }
    // eslint-disable-next-line
  }, [props.currentTreeTableInfo])

  return (
    <div className="treeSelectionGraphContainer">
      {treeSelectionGraph &&
      props.currentTreeTableInfo &&
      props.currentTreeTableInfo.showSelectionGraph &&
      props.currentTreeTableInfo.showTreeTable ? (
        <div className="selectionGraphOnContainer">
          <div className="selectionGraphTitles">
            <p className="selectionGraphTitle" data-textattribute={'title-209'}>
              {getText('title-209', user.settings)}
            </p>
            <p
              className="selectionGraphToggleText"
              id={`selectionGraphToggleText-hide-${props.treeIndex}`}
              onClick={() => toggleSelectionGraph(false)}
              data-textattribute={'button-141'}
            >
              {getText('button-141', user.settings)}
            </p>
          </div>

          <SelectionGraph
            treeSelectionGraph={treeSelectionGraph}
            resultsSnapshot={scenarioSnapshot.currentSnapshot}
            treeTableColumnsState={treeTableColumnsState}
          />
        </div>
      ) : (
        <div className="selectionGraphOffContainer">
          <p
            className="selectionGraphToggleText"
            onClick={() => {
              //Mixpanel 165 (All)
              logActivity(freemium.isFreemium, 'Showed tree selection graph')
              toggleSelectionGraph(true)
            }}
            id={`selectionGraphToggleText-show-${props.treeIndex}`}
            data-textattribute={'button-142'}
          >
            {getText('button-142', user.settings)}
          </p>
        </div>
      )}
    </div>
  )
}
