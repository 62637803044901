import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import {
  LegalCostsParty,
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../../../models/enums'
import {
  ExecutiveSummaryVisibleElements,
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  getStyleFromWhitelabel,
  legalCostFontSize,
} from '../../../../../../../../services/reportFunctions'
import { getLegalCosts } from '../../../../../../../../services/legalCostsFunctions'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { getLegalCostsSingleValue } from '../../../../../../../../services/resultsFunctions'

type Props = {
  legalCostsDescriptions?: ReportLegalCostsType
  visibleElements?: ExecutiveSummaryVisibleElements
  party: LegalCostsParty
  proceedings: LegalCostsProceedings
  reportWhitelabel?: ReportWhitelabel
  fade: boolean | undefined
}

export default function LegalCostsExecutiveSummary3(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const hasSecondTrial = scenarioSnapshot.currentSnapshot.probSecondTrial! > 0
  const includeIncurredCosts = resultsSettings.settings.includeIncurredCosts

  const firstProceedingsDefaultMessage = hasSecondTrial
    ? includeIncurredCosts === false
      ? //title-281
        getText('title-281', user.settings)
      : getText('title-129', user.settings)
    : includeIncurredCosts === false
    ? //title-282
      getText('title-282', user.settings)
    : getText('title-130', user.settings)

  const secondProceedingsDefaultMessage =
    scenarioSnapshot.currentSnapshot.court === TypeOfInstance.Public_Court
      ? //title-131a
        includeIncurredCosts === false
        ? //title-283
          getText('title-283', user.settings)
        : getText('title-131a', user.settings)
      : //title-131b
      includeIncurredCosts === false
      ? //title-284
        getText('title-284', user.settings)
      : getText('title-131b', user.settings)

  const firstProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.firstOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.firstOwnLegalCostsDescription
      : ''
  const secondProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.secondOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.secondOwnLegalCostsDescription
      : ''

  return (
    <div
      className="legalCostsComponent"
      id={`legalCostsComponent-${props.party}${
        props.visibleElements ? '-forReport' : ''
      }`}
      data-openreplay-obscured
    >
      <div
        className="estimatedLegalCostsContainer"
        id={`estimatedLegalCostsContainer-${props.proceedings}-${props.party}${
          props.visibleElements ? '-forReport' : ''
        }`}
      >
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'noRoboto',
            false,
            true,
          )}
          className={`legalCostsTitle medium ${props.fade ? 'fadeText' : ''} ${
            props.visibleElements ? 'forReport' : ''
          }
           ${props.fade ? 'fade' : ''}
          `}
          id={`costsOfProceedingsTitle3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {props.proceedings === LegalCostsProceedings.additionalProceedings
            ? secondProceedingsText.length > 0
              ? secondProceedingsText
              : secondProceedingsDefaultMessage
            : firstProceedingsText.length > 0
            ? firstProceedingsText
            : firstProceedingsDefaultMessage}
          :
        </p>
        <p
          className={`legalCostsValue bold robotoNumbersBold ${
            props.fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          style={{
            ...getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.bold,
              'roboto',
              false,
              props.fade,
            ),
            ...legalCostFontSize(
              getLegalCostsSingleValue(
                getLegalCosts(
                  'estimatedCosts',
                  props.party,
                  props.proceedings,
                  scenarioSnapshot.currentSnapshot,
                  user.settings,
                  includeIncurredCosts,
                ),
                scenarioSnapshot.currentSnapshot.currency,
                user.settings,
              ),
            ),
          }}
          id={`estimatedLegalCostsValue3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {getLegalCostsSingleValue(
            getLegalCosts(
              'estimatedCosts',
              props.party,
              props.proceedings,
              scenarioSnapshot.currentSnapshot,
              user.settings,
              includeIncurredCosts,
            ),
            scenarioSnapshot.currentSnapshot.currency,
            user.settings,
          )}
        </p>
      </div>
      <div className="recoverableCostsContainer">
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
            true,
          )}
          className={`legalCostsTitle description ${
            props.fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          data-textattribute="label-89"
          id={`recoverableCostsTitle3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {getText('label-89', user.settings)}:
        </p>
        <p
          className={`legalCostsValue robotoNumbers ${
            props.fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
            false,
            props.fade,
          )}
          id={`recoverableLegalCostsValue3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {getLegalCostsSingleValue(
            getLegalCosts(
              'recoverableCosts',
              props.party,
              props.proceedings,
              scenarioSnapshot.currentSnapshot,
              user.settings,
            ),
            scenarioSnapshot.currentSnapshot.currency,
            user.settings,
          )}
        </p>
      </div>
      {includeIncurredCosts && (
        <div className="incurredCostsContainer">
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
              false,
              true,
            )}
            className={`legalCostsTitle description ${
              props.fade ? 'fadeText' : ''
            } ${props.visibleElements ? 'forReport' : ''}`}
            data-textattribute="label-91"
            id={`incurredCostsTitle3-${
              props.proceedings === LegalCostsProceedings.firstProceedings
                ? 'first'
                : 'second'
            }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
          >
            {getText('label-91', user.settings)}:
          </p>
          <p
            className={`legalCostsValue robotoNumbers ${
              props.fade ? 'fadeText' : ''
            } ${props.visibleElements ? 'forReport' : ''}`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
              false,
              props.fade,
            )}
            id={`incurredLegalCostsValue3-${
              props.proceedings === LegalCostsProceedings.firstProceedings
                ? 'first'
                : 'second'
            }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
          >
            {getLegalCostsSingleValue(
              getLegalCosts(
                'incurredCosts',
                props.party,
                props.proceedings,
                scenarioSnapshot.currentSnapshot,
                user.settings,
              ),
              scenarioSnapshot.currentSnapshot.currency,
              user.settings,
            )}
          </p>
        </div>
      )}
    </div>
  )
}
