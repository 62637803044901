import React, { useState } from 'react'
import { removeUnderscore } from '../../../services/commonFunctions'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import DisabledWindow from '../../disabledWindow/DisabledWindow'
import { useRecoilState, useRecoilValue } from 'recoil'
import { viewerState } from '../../../states/ViewerState'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { recoveryModeState } from '../../../states/RecoveryModeState'
import { freemiumState } from '../../../states/FreemiumState'
import PremiumFeatureIcon from '../../PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../models/freemiumEnums'

type Props = {
  id: string
  label: string
  name: string
  value: string
  width: string | number
  validateFunction?: (value: any) => boolean
  options: string[]
  readableOptions?: string[]
  error?: boolean
  errorMessage?: string
  smallField?: boolean
  onChange: (e: any) => void
  tabIndex?: number
  helperText?: string
  borderColor?: string
  labelTextAttribute: `label-${number}` | undefined
  errorTextAttribute?: `error-${number}`
  helperTextAttribute?: `description-${number}` | undefined
  disabledMessageTextAttribute?: `message-${number}`
  noHelperText?: boolean
  forAccess?: boolean
  disabled?: boolean
  disabledMessage?: string
  height?: string | number
  interestDropdownImg?: string[]
}

export default function DropDown(props: Props) {
  const user = useRecoilValue(userState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const [disabledMessageCoordinates, setDisabledMessageCoordinates] = useState<
    [number, number] | undefined
  >(undefined)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)

  const disabledMessage =
    //data-textattribute = 'message-67'
    props.disabledMessage ?? getText('message-67', user.settings)

  function handleChange(event: any) {
    let e: { target: { id: string; value: string } } = {
      target: {
        id: props.id,
        value: event.target.value,
      },
    }
    props.onChange(e)
  }

  const onClickDisabled = () => {
    if (props.disabled) {
      const element = document.getElementById(`container-${props.id}`)
      const viewportOffset = element!.getBoundingClientRect()

      let top = viewportOffset.top - 15
      const left = viewportOffset.left + 50
      const h = window.innerHeight
      if (h - top < 200) {
        top -= 130
      }

      if (freemium.isFreemium) {
        setFreemium({ ...freemium, showMessage: FreemiumMessageType.General })
      } else if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        if (props.disabledMessage && props.disabledMessage.length > 0) {
          setIsViewer({ ...isViewer, shaking: true })
          setDisabledMessageCoordinates([top, left])
        }
      }
    }
  }

  return (
    <>
      <div
        className={`dropdown
    ${props.error ? 'dropdown-error' : ''}
    `}
        id={`container-${props.id}`}
        onClick={onClickDisabled}
        style={props.disabled ? { cursor: 'not-allowed' } : {}}
      >
        <FormControl sx={{ m: 1, margin: 0 }} error={props.error}>
          <InputLabel
            id={`label-${props.id}`}
            data-textattribute={props.labelTextAttribute}
            sx={
              props.disabled
                ? { color: 'rgba(0, 0, 0, 0.38)', cursor: 'not-allowed' }
                : {}
            }
          >
            {props.label}
          </InputLabel>
          <Select
            id={props.id}
            labelId={`label-${props.id}`}
            name={`select-${props.name}`}
            label={props.label}
            value={props.value}
            defaultValue={props.value}
            variant="outlined"
            onChange={handleChange}
            error={props.error}
            disabled={props.disabled}
            readOnly={props.disabled}
            sx={
              props.forAccess
                ? {
                    width: props.width,
                    backgroundColor: 'white',
                    margin: 0,
                    height: 40,
                    marginTop: '22px',
                    borderColor: 'transparent',
                    '& .MuiInputLabel-root.Mui-focused': {
                      borderColor: 'transparent',
                    },
                    '& .Mui-focused': {
                      '& > fieldset': {
                        borderColor: 'transparent !important',
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-root:hover': {
                      '& > fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }
                : props.disabled
                ? {
                    '&.Mui-disabled': {
                      '& > div': { cursor: 'not-allowed' },
                    },
                    width: props.width,
                    height: props.height ?? 'undefined',
                    backgroundColor: 'white',
                    margin: 0,
                  }
                : {
                    height: props.height ?? 'undefined',
                    width: props.width,
                    backgroundColor: 'white',
                    margin: 0,
                  }
            }
            data-openreplay-obscured
          >
            {props.options.map((option, index) => {
              return (
                <MenuItem
                  value={option}
                  key={index}
                  sx={{ zIndex: 7000 }}
                  tabIndex={props.tabIndex}
                  data-openreplay-obscured
                  id={`${props.id}-option-${index}`}
                >
                  {props.readableOptions
                    ? props.readableOptions[index]
                    : removeUnderscore(option)}
                  {freemium.isFreemium &&
                    option === getText('label-106', user.settings) &&
                    props.value !== getText('label-106', user.settings) && (
                      <PremiumFeatureIcon
                        targetId={`${props.id}-option-${index}`}
                        forDropDownMenu
                      />
                    )}
                  {props.interestDropdownImg ? (
                    <img
                      src={props.interestDropdownImg[index]}
                      height={23}
                      width={index === 2 ? 36 : 20}
                      alt="interestDropdownImg"
                      className="interestDropdownImg"
                      style={{ marginLeft: 7 }}
                    />
                  ) : null}
                </MenuItem>
              )
            })}
          </Select>
          {!props.noHelperText && (
            <FormHelperText
              data-textattribute={
                props.error
                  ? props.errorTextAttribute
                  : props.helperText
                  ? props.helperTextAttribute
                  : ' '
              }
            >
              {props.error
                ? props.errorMessage
                : props.helperText
                ? props.helperText
                : ' '}
            </FormHelperText>
          )}
        </FormControl>
      </div>
      {disabledMessageCoordinates && (
        <DisabledWindow
          id={props.id}
          message={disabledMessage}
          disabledMessageTextAttribute={
            props.disabledMessageTextAttribute ?? 'message-67'
          }
          cancelFunction={() => setDisabledMessageCoordinates(undefined)}
          position={disabledMessageCoordinates}
        />
      )}
    </>
  )
}
