import React from 'react'
import {
  AllowedAccessRole,
  AllowedAccessRoleFromString,
  AvailableLanguages,
  NorwegianAllowedAccessRole,
  translateAllowedAccessRole,
  UserRole,
} from '../../../models/enums'
import { UserForAccess } from '../../../models/generalTypes'
import { dropDownValidation } from '../../../Modules/AppModules/ValidationFunctions'
import DropDown from '../../Inputs/DropDown/DropDown'
import { AccessMenuAction } from '../AccessComponent'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'

type Props = {
  user: UserForAccess
  tempAccessRole: AllowedAccessRole
  changeAccessButtonType: (value: AccessMenuAction, index: number) => void
  changeTempAccessRole: (value: AllowedAccessRole, index: number) => void
  index: number
  forInviting?: boolean
}

const UserAccessRole = (props: Props) => {
  const user = useRecoilValue(userState)
  const allowedAccessRole =
    user.settings.language === AvailableLanguages.english
      ? AllowedAccessRole
      : NorwegianAllowedAccessRole

  const onChange = (e: any) => {
    const newAccessRole = AllowedAccessRoleFromString(e.target.value)
    props.changeTempAccessRole(newAccessRole, props.index)
    if (
      props.user.access_role &&
      newAccessRole.toString() !== props.user.access_role.toString()
    ) {
      props.changeAccessButtonType('change', props.index)
    } else {
      props.changeAccessButtonType(
        props.user.self ? 'leave' : props.forInviting ? 'add' : 'remove',
        props.index,
      )
    }
  }

  const getAvailableOptions = () => {
    const values = props.forInviting ? [allowedAccessRole.NONE] : []

    if (props.user.user_role === UserRole.GLOBAL_ADMIN) {
      values.push(allowedAccessRole.OWNER)
    } else if (props.user.user_role !== UserRole.APP_VIEWER) {
      values.push(
        allowedAccessRole.VIEWER,
        allowedAccessRole.EDITOR,
        allowedAccessRole.OWNER,
      )
    } else {
      values.push(allowedAccessRole.VIEWER)
    }

    return values
  }

  return (
    <>
      <DropDown
        id={'dropDownAccessRole'}
        label={''}
        name={'dropDownAccessRole'}
        value={translateAllowedAccessRole(
          props.tempAccessRole,
          AvailableLanguages.english,
          user.settings.language,
        )}
        width={135}
        validateFunction={(value: string) => dropDownValidation(value)}
        options={getAvailableOptions()}
        onChange={onChange}
        labelTextAttribute={undefined}
        forAccess
      />
    </>
  )
}

export default UserAccessRole
