import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { reportState } from '../../../../../../../../states/ReportState'
import tickedImg from '../../../../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../../../../resources/images/018-untickedGrayBlue.svg'
import sortOrderArrowImg from '../../../../../../../../resources/images/212-sortOrderArrow.svg'
import sortOrderDividerImg from '../../../../../../../../resources/images/211-sortOrderDivider.svg'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { SectionId } from '../../../../../../../../models/treeModels/treeTypes'
import { userState } from '../../../../../../../../states/UserState'
import {
  getText,
  reportSectionType,
} from '../../../../../../../../services/textFunctions'
import { SectionTitleKeys } from '../../../../../../../../models/reportModels/reportEnums'
import { freemiumState } from '../../../../../../../../states/FreemiumState'

type Props = {
  sectionId: SectionId
  animationPair: SectionId[]
  setAnimationPair: (animationPair: SectionId[]) => void
}
export function SectionOrderComponent(props: Props) {
  const [report, setReport] = useRecoilState(reportState)
  const [hover, setHover] = useState(false)
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [isAnimation, setIsAnimation] = useState<
    undefined | 'animateUp' | 'animateDown'
  >(undefined)

  const sectionFromReport = report.reportData.sections.filter(
    (section) => section.sectionId === props.sectionId,
  )[0]

  useEffect(() => {
    if (props.animationPair[0] === props.sectionId) {
      setIsAnimation('animateUp')
    }
    if (props.animationPair[1] === props.sectionId) {
      setIsAnimation('animateDown')
    }
    setTimeout(() => {
      props.setAnimationPair([])
      setIsAnimation(undefined)
    }, 600)
    // eslint-disable-next-line
  }, [report])

  function handleChangeSectionShow() {
    let tempReport = deepCloneObject(report)
    const sectionIndex = report.reportData.sections.findIndex(
      (section) => section.sectionId === sectionFromReport.sectionId,
    )

    if (tempReport.reportData.sections[sectionIndex].show) {
      //Mixpanel 148 (All)
      logActivity(freemium.isFreemium, 'Hid a section in the report', {
        'Hide from': 'Section Order Menu',
        section: sectionFromReport.title,
      })
    } else {
      //Mixpanel 149 (All)
      logActivity(freemium.isFreemium, 'Included a section in the report', {
        'Include from': 'Section Order Menu',
        section: sectionFromReport.title,
      })
    }

    tempReport.reportData.sections[sectionIndex].show =
      !tempReport.reportData.sections[sectionIndex].show
    setReport(tempReport)
  }

  function handleChangeSectionOrder(value: 'up' | 'down') {
    //Mixpanel 144 (All)
    logActivity(freemium.isFreemium, 'Reordered a section in the report', {
      'Reorder from': `Section Order Menu`,
    })

    let tempReport = deepCloneObject(report)
    const sectionIndex = report.reportData.sections.findIndex(
      (section) => section.sectionId === sectionFromReport.sectionId,
    )
    const sectionOrder =
      tempReport.reportData.sections[sectionIndex].sectionOrder
    if (value === 'up') {
      tempReport.reportData.sections[sectionIndex].sectionOrder -= 1
      const upSectionIndex = report.reportData.sections.findIndex(
        (section) => section.sectionOrder === sectionOrder - 1,
      )
      tempReport.reportData.sections[upSectionIndex].sectionOrder += 1

      props.setAnimationPair([
        props.sectionId,
        tempReport.reportData.sections[upSectionIndex].sectionId,
      ])
      setReport(tempReport)
    } else if (value === 'down') {
      tempReport.reportData.sections[sectionIndex].sectionOrder += 1
      const downSectionIndex = report.reportData.sections.findIndex(
        (section) => section.sectionOrder === sectionOrder + 1,
      )
      tempReport.reportData.sections[downSectionIndex].sectionOrder -= 1
      props.setAnimationPair([
        tempReport.reportData.sections[downSectionIndex].sectionId,
        props.sectionId,
      ])
      setReport(tempReport)
    }
  }

  return (
    <>
      {sectionFromReport && (
        <div
          className={`sectionOrderComponentContainer ${
            isAnimation === 'animateDown'
              ? 'animateDown50'
              : isAnimation === 'animateUp'
              ? 'animateUp50'
              : undefined
          }`}
          id={`sectionOrderComponentContainer${props.sectionId}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div
            className={`orderArrowsContainer ${hover ? '' : 'hidden'} ${
              sectionFromReport.sectionOrder ===
              report.reportData.sections.length - 1
                ? 'aloneUp'
                : sectionFromReport.sectionOrder === 0
                ? 'aloneDown'
                : ''
            }`}
          >
            {sectionFromReport.sectionOrder !== 0 && (
              <img
                className={`orderArrowUpImg ${
                  sectionFromReport.sectionOrder ===
                  report.reportData.sections.length - 1
                    ? 'aloneUp'
                    : undefined
                }`}
                onClick={() => handleChangeSectionOrder('up')}
                src={sortOrderArrowImg}
                alt="sortOrderArrowImg"
                id={`orderArrowUpImg-${props.sectionId}`}
              />
            )}
            {sectionFromReport.sectionOrder !== 0 &&
              sectionFromReport.sectionOrder !==
                report.reportData.sections.length - 1 && (
                <img
                  className="sortOrderDividerImg"
                  src={sortOrderDividerImg}
                  alt="sortOrderDividerImg"
                />
              )}
            {sectionFromReport.sectionOrder !==
              report.reportData.sections.length - 1 && (
              <img
                className={`orderArrowDownImg ${
                  sectionFromReport.sectionOrder === 0 ? 'aloneDown' : undefined
                }`}
                onClick={() => handleChangeSectionOrder('down')}
                src={sortOrderArrowImg}
                alt="sortOrderArrowImg"
                id={`orderArrowDownImg-${props.sectionId}`}
              />
            )}
          </div>
          <div className="includeSectionContainer">
            {sectionFromReport.show === true ? (
              <img
                src={tickedImg}
                alt="tickedImg"
                className="tickImg"
                id={`tickedButton-${sectionFromReport.title}-${sectionFromReport.customTitle}`}
                tabIndex={30000}
                onClick={() => handleChangeSectionShow()}
              />
            ) : (
              <img
                src={unTickedImg}
                alt="unTickedImg"
                className="tickImg"
                id={`untickedButton-${sectionFromReport.title}-${sectionFromReport.customTitle}`}
                tabIndex={30000}
                onClick={() => handleChangeSectionShow()}
              />
            )}
          </div>
          <div
            className="sectionTitleContainer"
            onClick={() => handleChangeSectionShow()}
          >
            <p className="sectionTitle" id={`sectionTitle-${props.sectionId}`}>
              {getText('submenu-15', user.settings)}{' '}
              {sectionFromReport.customTitle !== ''
                ? sectionFromReport.customTitle
                : reportSectionType(user.settings)[
                    sectionFromReport.title as SectionTitleKeys
                  ]}
            </p>
          </div>
        </div>
      )}
    </>
  )
}
