import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../../states/UserState'
import { getText } from '../../../../../../../../services/textFunctions'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import {
  findTotalClaimedAmount,
  findTotalCounterClaimedAmount,
} from '../../../../../../../../services/disputedAmountFunctions'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import {
  ExecutiveSummaryVisibleElements,
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  LegalCostsParty,
  LegalCostsProceedings,
} from '../../../../../../../../models/enums'
import LegalCostsExecutiveSummary3 from './LegalCostsExecutiveSummary3'
import {
  getStyleFromWhitelabel,
  snapshotHasLegalCosts,
} from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'

type Props = {
  party: LegalCostsParty
  visibleElements?: ExecutiveSummaryVisibleElements
  legalCostsDescriptions?: ReportLegalCostsType
  reportWhitelabel?: ReportWhitelabel
  hasSecondTrial: boolean
  resultsViewParty: 'client' | 'opposing'
  fade: boolean | undefined
}

export default function PartyClaimsAndLegalCosts(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  return (
    <div
      className={`partyClaimsAndLegalCostsComponent ${
        props.resultsViewParty === 'client' ? '' : 'opposingPartyView'
      } ${props.visibleElements ? 'forReport' : ''}`}
      id={`partyClaimsAndLegalCosts-${props.party}`}
      data-openreplay-obscured
    >
      <div className="partyNameContainer">
        <p
          className={`partyName ${props.fade ? 'fade' : ''}`}
          id={`partyName-${props.party}${
            props.visibleElements ? '-forReport' : ''
          }`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'noRoboto',
          )}
        >
          {props.party === LegalCostsParty.clientLegalCosts
            ? getText(`title-223`, user.settings, partiesFormat)
            : getText(`title-224`, user.settings, partiesFormat)}
        </p>
      </div>
      <div
        className={`claimsAndLegalCostsContainer ${props.fade ? 'fade' : ''}`}
      >
        <div className="claimedAmountContainer">
          <p
            className={`claimedAmountText ${props.fade ? 'fade' : ''}`}
            id={`claimedAmount-${props.party}${
              props.visibleElements ? '-forReport' : ''
            }`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
              false,
              true,
            )}
            data-textattribute="title-273"
          >
            {getText('title-273', user.settings)}
          </p>
          <p
            className={`claimedAmountValue ${
              props.fade ? 'fade' : ''
            } robotoNumbersBold`}
            id={`claimedAmountValue-${props.party}${
              props.visibleElements ? '-forReport' : ''
            }`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.bold,
              'roboto',
            )}
          >
            {stringAndRoundBigNumbers(
              props.party === LegalCostsParty.clientLegalCosts
                ? Math.abs(
                    findTotalClaimedAmount(scenarioSnapshot.currentSnapshot),
                  )
                : Math.abs(
                    findTotalCounterClaimedAmount(
                      scenarioSnapshot.currentSnapshot,
                    ),
                  ),
              user.settings,
              false,
            )}{' '}
            {scenarioSnapshot.currentSnapshot.currency}
            <sup
              style={{
                ...getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.bold,
                  'roboto',
                ),
                position: 'absolute',
                fontSize: '10px',
              }}
              className={`${props.fade ? 'fade' : ''} robotoNumbersBold`}
            >
              1
            </sup>
          </p>
        </div>
        {snapshotHasLegalCosts(scenarioSnapshot.currentSnapshot) && (
          <div
            className={`legalCostsContainer ${
              props.visibleElements && 'forReport'
            }`}
          >
            <LegalCostsExecutiveSummary3
              party={props.party}
              visibleElements={props.visibleElements}
              legalCostsDescriptions={props.legalCostsDescriptions}
              proceedings={LegalCostsProceedings.firstProceedings}
              fade={props.fade}
              reportWhitelabel={props.reportWhitelabel}
            />
            {props.hasSecondTrial && (
              <LegalCostsExecutiveSummary3
                party={props.party}
                visibleElements={props.visibleElements}
                legalCostsDescriptions={props.legalCostsDescriptions}
                proceedings={LegalCostsProceedings.additionalProceedings}
                reportWhitelabel={props.reportWhitelabel}
                fade={props.fade}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
